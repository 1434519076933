export class ReiriIcons {
  static full_screen = '/icon/full_screen.svg';
  static full_screen_exit = '/icon/full_screen_exit.svg';
  static group = '/icon/group.svg';
  static page_up = '/icon/page_up.svg';
  static up = '/icon/up.svg'
  static zoom_in = '/icon/zoom_in.svg'
  static zoom_out = '/icon/zoom_out.svg'

  static ac_1 = '/icon/ac_1.svg';
  static ac_2 = '/icon/ac_2.svg';
  static ac_3 = '/icon/ac_3.svg';
  static ac_4 = '/icon/ac_4.svg';
  static ac_5 = '/icon/ac_5.svg';
  static ac_6 = '/icon/ac_6.svg';
  static ac_7 = '/icon/ac_7.svg';
  static ac_8 = '/icon/ac_8.svg';
  static apu_crc = '/icon/apu_crc.svg';
  static apu_pet = '/icon/apu_pet.svg';
  static add_to_favorite = '/icon/add_to_favorite.svg';
  static admin = '/icon/admin.svg';
  static afternoon = '/icon/afternoon.svg';
  static ahu = '/icon/ahu.svg';
  static air_conditioning = '/icon/air_conditioning.svg';
  static alert = '/icon/alert.svg';
  static alexa = '/icon/alexa.svg';
  static amazon = '/icon/amazon.svg';
  static arrow_down = '/icon/arrow_down.svg';
  static arrow_left = '/icon/arrow_left.svg';
  static arrow_right = '/icon/arrow_right.svg';
  static arrow_up = '/icon/arrow_up.svg';
  static arrow_upper_right = '/icon/arrow_upper_right.svg';
  static auto = '/icon/auto.svg';
  static auto_change_over = '/icon/auto_change_over.svg';
  static auto_colour = '/icon/auto_colour.svg';
  static auto_default = '/icon/auto_default.svg';
  static auto_dry = '/icon/auto_dry.svg';
  static auto_ventilation = '/icon/auto_ventilation.svg';
  static bathroom = '/icon/bathroom.svg';
  static bathvent_H = '/icon/bathvent_H.svg';
  static bathvent_L = '/icon/bathvent_L.svg';
  static bathvent_stop = '/icon/bathvent_stop.svg';
  static bath_dry = '/icon/bath_dry.svg';
  static bath_ventilation = '/icon/bath_ventilation.svg';
  static battery_0 = '/icon/battery_0.svg';
  static battery_100 = '/icon/battery_100.svg';
  static battery_20 = '/icon/battery_20.svg';
  static battery_50 = '/icon/battery_50.svg';
  static battery_80 = '/icon/battery_80.svg';
  static bed_room = '/icon/bed_room.svg';
  static building = '/icon/building.svg';
  static ch_master = '/icon/ch_master.svg';
  static check = '/icon/check.svg';
  static chevron_down = '/icon/chevron_down.svg';
  static chevron_left = '/icon/chevron_left.svg';
  static chevron_right = '/icon/chevron_right.svg';
  static chevron_up = '/icon/chevron_up.svg';
  static circle = '/icon/circle.svg';
  static clean_filter = '/icon/clean_filter.svg';
  static cloudy_day = '/icon/cloudy_day.svg';
  static co2_sensor = '/icon/co2_sensor.svg';
  static controller = '/icon/controller.svg';
  static cooling = '/icon/cooling.svg';
  static cross = '/icon/cross.svg';
  static curtain = '/icon/curtain.svg';
  static daikin_air_purifier = '/icon/daikin_air_purifier.svg';
  static dashboard = '/icon/dashboard.svg';
  static DCPH02 = '/icon/DCPH02.svg';
  static default_group = '/icon/default_group.svg';
  static default_scene = '/icon/default_scene.svg';
  static defrost = '/icon/defrost.svg';
  static dehumidifier = '/icon/dehumidifier.svg';
  static delete = '/icon/delete.svg';
  static door = '/icon/door.svg';
  static double_bed = '/icon/double_bed.svg';
  static dry = '/icon/dry.svg';
  static econo_mode = '/icon/econo_mode.svg';
  static edit_1 = '/icon/edit_1.svg';
  static edit_2 = '/icon/edit_2.svg';
  static electric = '/icon/electric.svg';
  static execute = '/icon/execute.svg';
  static eyes_hidden = '/icon/eyes_hidden.svg';
  static eyes_visible = '/icon/eyes_visible.svg';
  static fan = '/icon/fan.svg';
  static fan2_1 = '/icon/fan2_1.svg';
  static fan2_2 = '/icon/fan2_2.svg';
  static fan2_auto = '/icon/fan2_auto.svg';
  static fan3_1 = '/icon/fan3_1.svg';
  static fan3_2 = '/icon/fan3_2.svg';
  static fan3_3 = '/icon/fan3_3.svg';
  static fan3_auto = '/icon/fan3_auto.svg';
  static fan3_quiet = '/icon/fan3_quiet.svg';
  static fan5_1 = '/icon/fan5_1.svg';
  static fan5_2 = '/icon/fan5_2.svg';
  static fan5_3 = '/icon/fan5_3.svg';
  static fan5_4 = '/icon/fan5_4.svg';
  static fan5_5 = '/icon/fan5_5.svg';
  static fan5_auto = '/icon/fan5_auto.svg';
  static fan5_quiet = '/icon/fan5_quiet.svg';
  static fan_1 = '/icon/fan_1.svg';
  static fan_2 = '/icon/fan_2.svg';
  static favorite_default = '/icon/favorite_default.svg';
  static favorite_filled = '/icon/favorite_filled.svg';
  static fire_alarm = '/icon/fire_alarm.svg';
  static flap2_0 = '/icon/flap2_0.svg';
  static flap2_1 = '/icon/flap2_1.svg';
  static flap2_2 = '/icon/flap2_2.svg';
  static flap2_3 = '/icon/flap2_3.svg';
  static flap2_4 = '/icon/flap2_4.svg';
  static flap2_none = '/icon/flap2_none.svg';
  static flap_0 = '/icon/flap_0.svg';
  static flap_2 = '/icon/flap_2.svg';
  static flap_3 = '/icon/flap_3.svg';
  static flap_4 = '/icon/flap_4.svg';
  static flap_A = '/icon/flap_A.svg';
  static flap_N = '/icon/flap_N.svg';
  static flap_S = '/icon/flap_S.svg';
  static flashlight = '/icon/flashlight.svg';
  static flood_sensor = '/icon/flood_sensor.svg';
  static floor = '/icon/floor.svg';
  static freshup_default = '/icon/freshup_default.svg';
  static freshup_selected = '/icon/freshup_selected.svg';
  static garage = '/icon/garage.svg';
  static good_sleep = '/icon/good_sleep.svg';
  static google = '/icon/google.svg';
  static google_home = '/icon/google_home.svg';
  static history = '/icon/history.svg';
  static holiday_calender = '/icon/holiday_calender.svg';
  static horizontal_flap_0 = '/icon/horizontal_flap_0.svg';
  static horizontal_flap_1 = '/icon/horizontal_flap_1.svg';
  static horizontal_flap_2 = '/icon/horizontal_flap_2.svg';
  static horizontal_flap_3 = '/icon/horizontal_flap_3.svg';
  static horizontal_flap_4 = '/icon/horizontal_flap_4.svg';
  static horizontal_flap_A = '/icon/horizontal_flap_A.svg';
  static horizontal_flap_N = '/icon/horizontal_flap_N.svg';
  static horizontal_flap_none = '/icon/horizontal_flap_none.svg';
  static horizontal_flap_S = '/icon/horizontal_flap_S.svg';
  static house = '/icon/house.svg';
  static hrv = '/icon/hrv.svg';
  static HRV_auto = '/icon/HRV_auto.svg';
  static HRV_auto_selected = '/icon/HRV_auto_selected.svg';
  static HRV_bypass = '/icon/HRV_bypass.svg';
  static HRV_bypass_selected = '/icon/HRV_bypass_selected.svg';
  static HRV_ex = '/icon/HRV_ex.svg';
  static HRV_ex_selected = '/icon/HRV_ex_selected.svg';
  static humid = '/icon/humid.svg';
  static humidity_Control_1 = '/icon/humidity_Control_1.svg';
  static humidity_Control_2 = '/icon/humidity_Control_2.svg';
  static humidity_Control_3 = '/icon/humidity_Control_3.svg';
  static iaq_monitoring = '/icon/iaq_monitoring.svg';
  static iaq_setting = '/icon/iaq_setting.svg';
  static illuminance = '/icon/illuminance.svg';
  static indoor = '/icon/indoor.svg';
  static interlock = '/icon/interlock.svg';
  static ip_camera = '/icon/ip_camera.svg';
  static keycard = '/icon/keycard.svg';
  static kitchen = '/icon/kitchen.svg';
  static language = '/icon/language.svg';
  static layout = '/icon/layout.svg';
  static level_1 = '/icon/level_1.svg';
  static level_2 = '/icon/level_2.svg';
  static level_3 = '/icon/level_3.svg';
  static light_1 = '/icon/light_1.svg';
  static light_2 = '/icon/light_2.svg';
  static light_3 = '/icon/light_3.svg';
  static light_4 = '/icon/light_4.svg';
  static living_room = '/icon/living_room.svg';
  static lock_1 = '/icon/lock_1.svg';
  static lock_2 = '/icon/lock_2.svg';
  static lock_3 = '/icon/lock_3.svg';
  static logout = '/icon/logout.svg';
  static meter = '/icon/meter.svg';
  static more = '/icon/more.svg';
  static morning = '/icon/morning.svg';
  static motion_sensor = '/icon/motion_sensor.svg';
  static multistate = '/icon/multistate.svg';
  static non = '/icon/non.svg';
  static normal_default = '/icon/normal_default.svg';
  static normal_selected = '/icon/normal_selected.svg';
  static no_connection = '/icon/no_connection.svg';
  static no_data = '/icon/no_data.svg';
  static no_group = '/icon/no_group.svg';
  static no_link = '/icon/no_link.svg';
  static no_scene = '/icon/no_scene.svg';
  static office = '/icon/office.svg';
  static password = '/icon/password.svg';
  static pattern = '/icon/pattern.svg';
  static pm25 = '/icon/pm25.svg';
  static point_setup = '/icon/point_setup.svg';
  static power = '/icon/power.svg';
  static power_mode = '/icon/power_mode.svg';
  static pre_heat = '/icon/pre_heat.svg';
  static pump = '/icon/pump.svg';
  static qr_code = '/icon/qr_code.svg';
  static question = '/icon/question.svg';
  static rainy_day = '/icon/rainy_day.svg';
  static reheat_dry = '/icon/reheat_dry.svg';
  static reiri_iaq_sensor = '/icon/reiri_iaq_sensor.svg';
  static reiri_logo = '/icon/reiri_logo.svg';
  static report_energy_management =
    '/icon/report_energy_management.svg';
  static report_error_report = '/icon/report_error_report.svg';
  static report_operation_report =
    '/icon/report_operation_report.svg';
  static report_settings = '/icon/report_settings.svg';
  static report_water_management =
    '/icon/report_water_management.svg';
  static resort_villa = '/icon/resort_villa.svg';
  static room = '/icon/room.svg';
  static save_data = '/icon/save_data.svg';
  static scan = '/icon/scan.svg';
  static scene_back_home = '/icon/scene_back_home.svg';
  static scene_leave_home = '/icon/scene_leave_home.svg';
  static scene_lunch = '/icon/scene_lunch.svg';
  static scene_night = '/icon/scene_night.svg';
  static scene_office_time = '/icon/scene_office_time.svg';
  static scene_open_office = '/icon/scene_open_office.svg';
  static scene_sleep = '/icon/scene_sleep.svg';
  static scene_wake_up = '/icon/scene_wake_up.svg';
  static schedule = '/icon/schedule.svg';
  static screen = '/icon/screen.svg';
  static showcase1 = 'icon/showcase1.svg';
  static showcase2 = 'icon/showcase2.svg';
  static search = '/icon/search.svg';
  static sensor = '/icon/sensor.svg';
  static setting_2 = '/icon/setting_2.svg';
  static shutter_down = '/icon/shutter_down.svg';
  static shutter_roller = '/icon/shutter_roller.svg';
  static shutter_stop = '/icon/shutter_stop.svg';
  static shutter_up = '/icon/shutter_up.svg';
  static single_bed = '/icon/single_bed.svg';
  static smoke_detector = '/icon/smoke_detector.svg';
  static sort_ascend = '/icon/sort_ascend.svg';
  static sort_descend = '/icon/sort_descend.svg';
  static status_1 = '/icon/status_1.svg';
  static status_2 = '/icon/status_2.svg';
  static streamer_discharge_air_purifying =
    '/icon/streamer_discharge_air_purifying.svg';
  static study_room = '/icon/study_room.svg';
  static suite_room = '/icon/suite_room.svg';
  static sun = '/icon/sun.svg';
  static sunny_day = '/icon/sunny_day.svg';
  static switch_1 = '/icon/switch_1.svg';
  static switch_2 = '/icon/switch_2.svg';
  static tab_group_active = '/icon/tab_group_active.svg';
  static tab_group_default = '/icon/tab_group_default.svg';
  static tab_homepage_active = '/icon/tab_homepage_active.svg';
  static tab_homepage_default = '/icon/tab_homepage_default.svg';
  static tab_report_active = '/icon/tab_report_active.svg';
  static tab_report_default = '/icon/tab_report_default.svg';
  static tab_scene_active = '/icon/tab_scene_active.svg';
  static tab_scene_default = '/icon/tab_scene_default.svg';
  static tab_setting_active = '/icon/tab_setting_active.svg';
  static tab_setting_default = '/icon/tab_setting_default.svg';
  static terms_conditions = '/icon/terms_conditions.svg';
  static thermometer = '/icon/thermometer.svg';
  static thermometer_cool = '/icon/thermometer_cool.svg';
  static thermometer_heat = '/icon/thermometer_heat.svg';
  static timer = '/icon/timer.svg';
  static trend_graph = '/icon/trend_graph.svg';
  static tv = '/icon/tv.svg';
  static tvoc_sensor = '/icon/tvoc_sensor.svg';
  static twin_bed = '/icon/twin_bed.svg';
  static user = '/icon/user.svg';
  static user_administration = '/icon/user_administration.svg';
  static version_information = '/icon/version_information.svg';
  static warning = '/icon/warning.svg';
  static window_1 = '/icon/window_1.svg';
  static window_2 = '/icon/window_2.svg';
  static windy_day = '/icon/windy_day.svg';

  static iconList = {
    'mode': {
      'C': ReiriIcons.cooling,
      'H': ReiriIcons.sun,
      'F': ReiriIcons.fan,
      'D': ReiriIcons.dry,
      'A': ReiriIcons.auto_colour,
      'T': ReiriIcons.air_conditioning,
    },
    'mode2': {
      "NON": ReiriIcons.non,
      "RD": ReiriIcons.reheat_dry,
      "AD": ReiriIcons.auto_dry,
      "GS": ReiriIcons.good_sleep,
      "BD": ReiriIcons.bath_dry,
      "PH": ReiriIcons.pre_heat,
    },
    'fanstep2': {
      "L": ReiriIcons.fan2_1,
      "H": ReiriIcons.fan2_2,
      "A": ReiriIcons.fan2_auto,
    },
    'fanstep3': {
      "L": ReiriIcons.fan3_1,
      "M": ReiriIcons.fan3_2,
      "H": ReiriIcons.fan3_3,
      "A": ReiriIcons.fan3_auto,
      "Q": ReiriIcons.fan3_quiet,
    },
    'fanstep5': {
      "L": ReiriIcons.fan5_1,
      "LM": ReiriIcons.fan5_2,
      "M": ReiriIcons.fan5_3,
      "MH": ReiriIcons.fan5_4,
      "H": ReiriIcons.fan5_5,
      "A": ReiriIcons.fan5_auto,
      "Q": ReiriIcons.fan5_quiet,
    },
    'fanstep3_hotel': {
      "L": ReiriIcons.fan3_1,
      "M": ReiriIcons.fan3_2,
      "H": ReiriIcons.fan3_3,
    },
    'flap': {
      "0": ReiriIcons.flap2_0,
      "1": ReiriIcons.flap2_1,
      "2": ReiriIcons.flap2_2,
      "3": ReiriIcons.flap2_3,
      "4": ReiriIcons.flap2_4,
      "N": ReiriIcons.flap_N,
      "S": ReiriIcons.flap_S,
      "A": ReiriIcons.flap_A,
    },
    'flap2': {
      "0": ReiriIcons.horizontal_flap_0,
      "1": ReiriIcons.horizontal_flap_1,
      "2": ReiriIcons.horizontal_flap_2,
      "3": ReiriIcons.horizontal_flap_3,
      "4": ReiriIcons.horizontal_flap_4,
      "N": ReiriIcons.horizontal_flap_none,
      "S": ReiriIcons.flap_S,
      "A": ReiriIcons.flap_A,
    },
    'vent': {
      'AV': ReiriIcons.auto_ventilation,
      'NV': ReiriIcons.bath_ventilation,
    },
    'bvent': {
      "S": ReiriIcons.bathvent_stop,
      "L": ReiriIcons.bathvent_L,
      "H": ReiriIcons.bathvent_H,
    },
    'hum': {
      "L": ReiriIcons.humidity_Control_1,
      "M": ReiriIcons.humidity_Control_2,
      "H": ReiriIcons.humidity_Control_3,
    },
    'vmode': {
      "A": ReiriIcons.HRV_auto,
      "HX": ReiriIcons.HRV_ex,
      "S": ReiriIcons.HRV_bypass,
    },
    'vamount': {
      "L": ReiriIcons.fan2_1,
      "H": ReiriIcons.fan2_2,
      "A": ReiriIcons.fan2_auto,
    },
    'apu_mode': {
      "MA": ReiriIcons.fan_1,
      "AA": ReiriIcons.auto,
      "ECO": ReiriIcons.econo_mode,
      "PET": ReiriIcons.apu_pet,
      "CRC": ReiriIcons.apu_crc,
    }
  };

  // icons for point
  static pointIconMap = {
    'AC1.png': ReiriIcons.ac_1,
    'AC2.png': ReiriIcons.ac_2,
    'AC3.png': ReiriIcons.ac_3,
    'AC4.png': ReiriIcons.ac_3,
    'AC5.png': ReiriIcons.ac_5,
    'AC6.png': ReiriIcons.ac_6,
    'AC7.png': ReiriIcons.ac_7,
    'AC8.png': ReiriIcons.ac_8,
    'AHU.png': ReiriIcons.ahu,
    'AirPurifire.png': ReiriIcons.daikin_air_purifier,
    'Alert.png': ReiriIcons.alert,
    'Curtain.png': ReiriIcons.curtain,
    'CO2.png': ReiriIcons.co2_sensor, // prepare new png icon
    'Dehumi.png': ReiriIcons.dehumidifier, // prepare new png icon
    'Door.png': ReiriIcons.door,
    'Dry.png': ReiriIcons.humid,
    'Fan1.png': ReiriIcons.fan_1,
    'Fan2.png': ReiriIcons.fan_2,
    'FireAlarm.png': ReiriIcons.fire_alarm,
    'FloodSensor.png': ReiriIcons.flood_sensor,
    'Hrv.png': ReiriIcons.hrv,
    'Humid.png': ReiriIcons.humid,
    'Illuminance.png': ReiriIcons.illuminance,
    'Keycard.png': ReiriIcons.keycard,
    'Level1.png': ReiriIcons.level_1,
    'Level2.png': ReiriIcons.level_2,
    'Level3.png': ReiriIcons.level_3,
    'Light1.png': ReiriIcons.light_2,
    'Light2.png': ReiriIcons.light_1,
    'Light3.png': ReiriIcons.light_3,
    'Light4.png': ReiriIcons.light_4,
    'Lock1.png': ReiriIcons.lock_1,
    'Lock2.png': ReiriIcons.lock_2,
    'Lock3.png': ReiriIcons.lock_3, // prepare new png icon
    'Meter.png': ReiriIcons.meter,
    'MotionSensor.png': ReiriIcons.motion_sensor,
    'Multistat.png': ReiriIcons.multistate,
    'PM2.5.png': ReiriIcons.pm25,
    'Pump.png': ReiriIcons.pump,
    'RollerShutter.png': ReiriIcons.shutter_roller,
    'Screen.png': ReiriIcons.screen,
    'Showcase1.png': ReiriIcons.showcase1,
    'Showcase2.png': ReiriIcons.showcase2,
    'SmokeDetector.png': ReiriIcons.smoke_detector,
    'Status1.png': ReiriIcons.status_1,
    'Status2.png': ReiriIcons.status_2,
    'Switch1.png': ReiriIcons.switch_2,
    'Switch2.png': ReiriIcons.switch_1,
    'Temp.png': ReiriIcons.thermometer,
    'TV.png': ReiriIcons.tv,
    'Window1.png': ReiriIcons.window_1,
    'Window2.png': ReiriIcons.window_2,
  };

  // icons for group
  static groupIconMap = {
    'DefaultGrp.png': ReiriIcons.default_group,
    'Building.png': ReiriIcons.building,
    'Floor.png': ReiriIcons.floor,
    'House.png': ReiriIcons.house,
    'Office.png': ReiriIcons.office,
    'Room.png': ReiriIcons.room,
    'LivingRoom.png': ReiriIcons.living_room, // prepare new png icon
    'StudyRoom.png': ReiriIcons.study_room, // prepare new png icon
    'BedRoom.png': ReiriIcons.bed_room, // prepare new png icon
    'Garage.png': ReiriIcons.garage, // prepare new png icon
    'Kitchen.png': ReiriIcons.kitchen, // prepare new png icon
    'Double.png': ReiriIcons.twin_bed,
    'Single.png': ReiriIcons.single_bed,
    'Suite.png': ReiriIcons.suite_room,
    'Twin.png': ReiriIcons.double_bed,
    'Resort.png': ReiriIcons.resort_villa,
    // point icon can use for group
    'AC1.png': ReiriIcons.ac_1,
    'AC2.png': ReiriIcons.ac_2,
    'AC3.png': ReiriIcons.ac_3,
    'AC4.png': ReiriIcons.ac_3,
    'AC5.png': ReiriIcons.ac_5,
    'AC6.png': ReiriIcons.ac_6,
    'AC7.png': ReiriIcons.ac_7,
    'AC8.png': ReiriIcons.ac_8,
    'AHU.png': ReiriIcons.ahu,
    'AirPurifire.png': ReiriIcons.daikin_air_purifier,
    'Alert.png': ReiriIcons.alert,
    'Curtain.png': ReiriIcons.curtain,
    'CO2.png': ReiriIcons.co2_sensor, // prepare new png icon
    'Dehumi.png': ReiriIcons.dehumidifier, // prepare new png icon
    'Door.png': ReiriIcons.door,
    'Dry.png': ReiriIcons.humid,
    'Fan1.png': ReiriIcons.fan_1,
    'Fan2.png': ReiriIcons.fan_2,
    'FireAlarm.png': ReiriIcons.fire_alarm,
    'FloodSensor.png': ReiriIcons.flood_sensor,
    'Hrv.png': ReiriIcons.hrv,
    'Humid.png': ReiriIcons.humid,
    'Illuminance.png': ReiriIcons.illuminance,
    'Keycard.png': ReiriIcons.keycard,
    'Level1.png': ReiriIcons.level_1,
    'Level2.png': ReiriIcons.level_2,
    'Level3.png': ReiriIcons.level_3,
    'Light1.png': ReiriIcons.light_2,
    'Light2.png': ReiriIcons.light_1,
    'Light3.png': ReiriIcons.light_3,
    'Light4.png': ReiriIcons.light_4,
    'Lock1.png': ReiriIcons.lock_1,
    'Lock2.png': ReiriIcons.lock_2,
    'Lock3.png': ReiriIcons.lock_3, // prepare new png icon
    'Meter.png': ReiriIcons.meter,
    'MotionSensor.png': ReiriIcons.motion_sensor,
    'Multistat.png': ReiriIcons.multistate,
    'PM2.5.png': ReiriIcons.pm25,
    'Pump.png': ReiriIcons.pump,
    'RollerShutter.png': ReiriIcons.shutter_roller,
    'Screen.png': ReiriIcons.screen,
    'Showcase1.png': ReiriIcons.showcase1,
    'Showcase2.png': ReiriIcons.showcase2,
    'SmokeDetector.png': ReiriIcons.smoke_detector,
    'Status1.png': ReiriIcons.status_1,
    'Status2.png': ReiriIcons.status_2,
    'Switch1.png': ReiriIcons.switch_2,
    'Switch2.png': ReiriIcons.switch_1,
    'Temp.png': ReiriIcons.thermometer,
    'TV.png': ReiriIcons.tv,
    'Window1.png': ReiriIcons.window_1,
    'Window2.png': ReiriIcons.window_2,
  };

  // icons for scene
  static sceneIconMap = {
    'DefaultScene.png': ReiriIcons.default_scene,
    'Backhome.png': ReiriIcons.scene_back_home,
    'Leave.png': ReiriIcons.scene_leave_home,
    'Lunch.png': ReiriIcons.scene_lunch,
    'Night.png': ReiriIcons.scene_night,
    'Officetime.png': ReiriIcons.scene_office_time,
    'OpenOffice.png': ReiriIcons.scene_open_office,
    'Sleep.png': ReiriIcons.scene_sleep,
    'Wakeup.png': ReiriIcons.scene_wake_up,
  };
}
