<template>
  <div class="report trend-report">
    <PopUpToast />
    <BarDateTime :showSelectTime="true" :defDate="defDate" />
    <div class="vertical-center">
      <div class="frame duration-holder vertical-center">
        <div class="label">{{ stringTable.word('duration') }}</div>
        <div class="val select-click-able" @click="changeDuration" role="button">
          {{ durationList[durationIndex].label }}
        </div>
      </div>
      <SelectDropDown ref="selectDropdown" mainLabel="select_device" :items="devicesDropDownDisplay"
        checkedString="chartChecked" :maxSelection="4" :isCloseFalse="true" :disableToggle="isChartLoading" />
    </div>
    <div class="navigation btm-line">
      <div class="btn-holder">
        <BtnNavIconText v-if="tabVisibility.temp" text="temp" :compoForChangeScreen="this" />
        <BtnNavIconText v-if="tabVisibility.humi" text="humi" :compoForChangeScreen="this" />
        <BtnNavIconText v-if="tabVisibility.co2" text="co2" :compoForChangeScreen="this" />
        <BtnNavIconText v-if="tabVisibility.pm25" text="pm25" :compoForChangeScreen="this" />
        <BtnNavIconText v-if="tabVisibility.tvoc" text="tvoc" :compoForChangeScreen="this" />
        <BtnNavIconText v-if="tabVisibility.others" text="others" :compoForChangeScreen="this" compoTarget="others" />
      </div>
    </div>
    <Chart :data="data" :options="options" :isLoading="isChartLoading" :legendDisable="true" />
  </div>
</template>

<style>
.trend-report {
  width: 100%;
}

.trend-report .duration-holder {
  display: flex;
  width: 50%;
  padding: 6px 14px;
  margin-right: 4px;
}

.trend-report .duration-holder .label {
  font-size: 1.1em;
}

.trend-report .duration-holder .val {
  margin-left: auto;
}

.trend-report .dropdown-menu {
  margin-left: -80px;
}

.trend-report .duration-holder,
.trend-report .select-dropdown-holder {
  width: 50%;
  height: 45px;
  margin-top: 8px;
}

.trend-report .btn-holder {
  justify-content: center;
}

.trend-report .btn-holder .item {
  padding: 8px 10px 6px 10px;
}

.trend-report .chart-holder {
  height: 370px;
}

.report-page .chart-holder {
  width: 710px;
}
</style>

<script>
import PopUpToast from './PopUpToast.vue'
import BarDateTime from './BarDateTime.vue'
import SelectDropDown from './SelectDropDown.vue'
import BtnNavIconText from './BtnNavIconText.vue'
import Chart from './Chart.vue'

export default {
  name: 'App',
  props: {
    commModule: Object,
  },
  data() {
    return {
      currentScreen: 'temp',
      defDate: null,
      startDtInput: new Date(),
      startTime: '00:00',
      startDTObj: new Date(),
      endDTObj: new Date(),
      durationIndex: 0,
      durationList: [
        { label: `48 ${this.stringTable.word('hours')}`, hours: 48, },
        { label: `7 ${this.stringTable.word('days')}`, hours: 168, },
        { label: `6 ${this.stringTable.word('hours')}`, hours: 6 },
        { label: `12 ${this.stringTable.word('hours')}`, hours: 12 },
        { label: `24 ${this.stringTable.word('hours')}`, hours: 24 },
      ],

      toast: {},
      savedDataSets: [],
      devicesDropDownDisplay: [],
      trendPointObj: {},
      firstLoad: true,
      isChartLoading: true,
      gotClickCheckBox: false,
      data: {},
      xAxisLabels: {
        temp: `${this.stringTable.word('temp')} (${this.stringTable.word('DEG')})`,
        humi: `${this.stringTable.word('humi')} (%)`,
        co2: `${this.stringTable.word('co2')} (ppm)`,
        pm25: `${this.stringTable.word('pm25')} (mg/m3)`,
        tvoc: `${this.stringTable.word('tvoc')} (mg/m3)`,
        others: '',
      },
      allUsages: ['temp', 'humi', 'co2', 'pm25', 'tvoc'],
      checkedPoints: { temp: [], humi: [], co2: [], pm25: [], tvoc: [], others: [] },
      tabVisibility: { temp: false, humi: false, co2: false, pm25: false, tvoc: false, others: false },
      options: {
        scales: {
          x: {
            grid: { display: false },
          },
          y: {
            title: {
              display: true,
              text: '',
            },
            ticks: { stepSize: 2 },
            type: 'linear',
            lineThickness: 1,
            display: true,
            position: 'left',
          },
        }
      },
    }
  },
  created() {
    this.defDate = new Date();
    this.defDate.setDate(this.defDate.getDate() - 1);
    this.setYaxisLabel();
    this.commModule.getTrendPoints();
  },
  watch: {
    startDtInput() {
      this.doGetTrend();
      this.closeDropDown();
    },
    currentScreen() {
      this.initDevDropDown();
      this.doGetTrend();
    },
    'commModule.trend_point_list'(newTrendPointList) {
      this.trendPointObj = {};
      newTrendPointList.forEach(tp => {
        if (!this.trendPointObj[tp[0]]) this.trendPointObj[tp[0]] = [];
        this.trendPointObj[tp[0]].push(tp[1]);
      });
      this.initDevDropDown();
      this.doGetTrend(newTrendPointList);
    },
    'commModule.get_trend'() {//newGetTrend
      this.prepSavedDatasets();
    },
  },
  computed: {
  },
  methods: {
    changeTime(name, newTime) {
      this.startTime = newTime;
      this.doGetTrend();
      this.closeDropDown();
    },
    changeDuration() {
      var added = this.durationIndex + 1;
      this.durationIndex = added >= this.durationList.length ? 0 : added;
      this.doGetTrend();
      this.closeDropDown();
    },
    getStartTime() {
      return new Date(this.startDTObj.getTime());
    },
    getDurationHours() {
      return this.durationList[this.durationIndex].hours;
    },
    getDurationMins() {
      return this.getDurationHours() * 60;
    },
    getEndDtObj(isMax) {
      var dtObj = this.getStartTime();
      if (isMax) {
        const durations = this.durationList.map(d => d.hours);
        const maxDuration = Math.max(...durations);
        dtObj.setHours(dtObj.getHours() + maxDuration);
      } else {
        dtObj.setHours(dtObj.getHours() + this.getDurationHours());
      }
      return dtObj;
    },
    setYaxisLabel() {
      var unitLabel = this.xAxisLabels[this.currentScreen];
      if (this.currentScreen == 'others' && this.checkedPoints.others.length > 0) {
        var pointId = this.checkedPoints.others[0];
        var point = this.commModule.point_list[pointId];
        if (point) {
          unitLabel = `${this.stringTable.word(point.usage)} (${point.unit})`;
        }
      }
      this.options.scales.y.title.text = unitLabel;
    },
    getDevicesByUsages(dataTrend) {
      var result = [];
      var usages = this.allUsages// + 'ac';
      var dataTrendKeys = Object.keys(dataTrend);
      for (const point_key in this.commModule.point_list) {
        var point = this.commModule.point_list[point_key];
        if (usages.includes(point.usage) || dataTrendKeys.includes(point_key)) {
          result.push(point);
        }
      }
      return result;
    },
    getAllStartToEndDate(isMaxEnd) {
      var dateList = [];
      var startDataDT = new Date(this.startDTObj);
      this.endDTObj = this.getEndDtObj(isMaxEnd);
      var minsInterval = this.getDurationMins() / 4 / 36;
      minsInterval = minsInterval < 5 ? 5 : minsInterval;
      while (startDataDT <= this.endDTObj) {
        dateList.push(new Date(startDataDT));
        startDataDT.setMinutes(startDataDT.getMinutes() + minsInterval);
      }
      return dateList;
    },
    setLabels() {
      var labelList = this.getAllStartToEndDate();
      var minuteInterval = this.getDurationMins() / 4;
      var intervalList = [];
      var tempStartDT = new Date(this.startDTObj);
      while (tempStartDT <= this.endDTObj) {
        intervalList.push(new Date(tempStartDT).getTime());
        tempStartDT.setMinutes(tempStartDT.getMinutes() + minuteInterval);
      }
      var dtLabelled = {};
      for (let i = 0; i < labelList.length; i++) {
        if (intervalList.includes(labelList[i].getTime())) {
          var hideDate = labelList[i].getDate() == dtLabelled.day &&
            labelList[i].getMonth() == dtLabelled.mth;
          dtLabelled.day = labelList[i].getDate();
          dtLabelled.mth = labelList[i].getMonth();
          labelList[i] = this.ConvertHelper.dtObjToLblStr(labelList[i], hideDate);
        }
        else labelList[i] = '';
      }
      this.data.labels = labelList;
    },
    doGetTrend(trendPointList) {
      if (!trendPointList && this.firstLoad) return;

      this.startDTObj = this.ConvertHelper.dtInputToDtObj(this.startDtInput);
      // this.startDTObj.setDate(this.startDTObj.getDate() - 1);
      var startTimeList = this.startTime.split(':');
      this.startDTObj.setHours(parseInt(startTimeList[0]));
      this.startDTObj.setMinutes(parseInt(startTimeList[1]));

      var maxEndDTObj = this.getEndDtObj();

      trendPointList = trendPointList ? trendPointList : this.commModule.trend_point_list;
      this.firstLoad = false;
      this.isChartLoading = true;
      var checkedDevs = this.devicesDropDownDisplay.filter(dev => dev.chartChecked);
      var checkedIds = checkedDevs.map(dev => dev.key_id);
      // Filter trend point
      trendPointList = trendPointList.filter(tp => checkedIds.includes(tp[0]));
      this.commModule.getTrend(this.startDTObj, maxEndDTObj, trendPointList);
    },
    prepSavedDatasets() {
      var enlistedDev = this.devicesDropDownDisplay.filter(dev => dev.chartChecked);
      // var enlistedDev = this.getDevicesByUsages(dataTrend);
      var dataTrend = this.commModule.get_trend.data;
      this.savedDataSets = [];

      for (const index in enlistedDev) {
        var currDevice = enlistedDev[index];
        var dt_point_id = currDevice.key_id;
        if (!currDevice) continue;

        var trendPointKeys = this.trendPointObj[dt_point_id];
        if (!trendPointKeys) continue;
        trendPointKeys.forEach(trendPointKey => {
          var dataTrendObj = dataTrend[dt_point_id];
          var dataWithDT = dataTrendObj ? dataTrendObj[trendPointKey] : [];
          var dataWithDTObj = {};
          if (dataWithDT) {
            dataWithDT.forEach(data => {
              data[0] = this.ConvertHelper.dtServerToDtObj(data[0]);
              var val = data[1];
              if (this.currentScreen == 'temp') {
                if (trendPointKey == 'sp') val = this.tempConvert.spConvert(val);
                else val = this.tempConvert.tempConvert(val);
              }
              dataWithDTObj[data[0].getTime()] = val;
            });
          }

          var label = currDevice.name;
          if (['sp', 'temp'].includes(trendPointKey))
            label += ` (${this.stringTable.word(trendPointKey)})`

          this.savedDataSets.push({
            currDevice, dataWithDTObj, label,
            data: [],
            // borderColor: aColor,
            // backgroundColor: aColor,
            yAxisID: 'y',
            borderWidth: 1.5
          });
        });
      }
      this.setDatasetFromSaved();
    },
    setDatasetFromSaved() {//isFirstFewPointsChecked
      this.options.scales.y.min = null;
      this.options.scales.y.ticks.stepSize = null;
      this.setLabels();
      var datePoints = this.getAllStartToEndDate();
      this.data.datasets = [];
      var index = 0;
      this.devicesDropDownDisplay.forEach((dev) => {
        if (dev.chartChecked) {
          var foundDataSets = this.savedDataSets.filter(saveDs => dev.key_id == saveDs.currDevice.key_id);
          var lineColor;
          if (foundDataSets.length == 0) {
            lineColor = this.ColorHelper.get(index); index++;
            this.data.datasets.push({
              label: dev.name,
              data: Array(datePoints.length).fill(null),
              borderColor: lineColor,
              backgroundColor: lineColor,
              yAxisID: 'y',
              borderWidth: 1.5
            });
          } else {
            foundDataSets.forEach(ds => {
              lineColor = this.ColorHelper.get(index); index++;
              ds.borderColor = lineColor;
              ds.backgroundColor = lineColor;
              this.data.datasets.push(ds);
              ds.data = [];
              var gotData = false;
              datePoints.forEach(datePoint => {
                var aVal = ds.dataWithDTObj[datePoint.getTime()];
                ds.data.push(aVal != null ? aVal : null)
                if (aVal && !gotData) gotData = true;
              });
              var maxVal = this.ConvertHelper.getMinOrMax(ds.data, false);
              maxVal = isFinite(maxVal) ? maxVal : 1;
              var toDivide = this.currentScreen == 'temp' ? 35 * this.tempConvert.spGap() : 50;
              var precise = gotData ? this.ConvertHelper.chartPrecision(maxVal, toDivide) : 1;
              var minVal = null;
              if (this.currentScreen == 'tvoc') {
                minVal = this.ConvertHelper.getMinOrMax(ds.data, true);
                minVal = isFinite(minVal) ? minVal : null;
              }
              this.options.scales.y.min = minVal;
              this.options.scales.y.ticks.stepSize = precise;
            });
          }
        }
      });
      this.isChartLoading = false;
    },
    clickedCheckBox(dev, isOverMax) {
      if (isOverMax) this.toast = { text: 'sel_dev_exceed', isError: true }
      else {
        this.gotClickCheckBox = true;
        this.checkedPoints[this.currentScreen] =
          this.devicesDropDownDisplay.filter(point => point.chartChecked)
            .map(point => point.key_id);
      }
    },
    initDevDropDown() {
      var devicesDropDownDisplayIds = [];
      this.devicesDropDownDisplay = [];
      var i = 0;
      var isCheckedPointsEmpty = this.checkedPoints[this.currentScreen].length == 0;
      for (const key in this.commModule.trend_point_list) {
        var trendPointId = this.commModule.trend_point_list[key][0];
        var point = this.commModule.point_list[trendPointId];
        if (point && !devicesDropDownDisplayIds.includes(point.key_id) &&
          ['Value', 'Ac', 'Dehumi', 'Refrige'].includes(point.type)) {
          var usages = point.usage;
          if (usages == 'ac') usages = 'temp';
          if (usages == 'refrige') usages = 'temp';
          if (usages == 'dehumi') usages = 'humi';
          if (!this.allUsages.includes(usages)) {
            usages = 'others';
            this.tabVisibility.others = true;
          } else {
            this.tabVisibility[usages] = true;
          }
          if (this.currentScreen == usages) {
            if (isCheckedPointsEmpty) {
              var lessThan4 = i < 4;
              point.chartChecked = lessThan4;
              if (lessThan4)
                this.checkedPoints[usages].push(point.key_id);
            } else
              point.chartChecked = this.checkedPoints[usages].includes(point.key_id);

            this.devicesDropDownDisplay.push(point);
            devicesDropDownDisplayIds.push(point.key_id);
            i++;
          }
        }
      }
      this.setYaxisLabel();
    },
    clickedDropDown(opened) {
      if (!opened && !this.isChartLoading && this.gotClickCheckBox) this.doGetTrend();
      if (opened) this.gotClickCheckBox = false;
      this.setYaxisLabel();
    },
    closeDropDown() {
      const selectDropdown = this.$refs.selectDropdown;
      if (selectDropdown) selectDropdown.closeDropDown();
    }
  },
  components: {
    PopUpToast,
    BarDateTime,
    SelectDropDown,
    BtnNavIconText,
    Chart,
  }
}
</script>

<!-- 
// var trendPointKey = this.commModule.trend_point_list.find(p => p[0] == dt_point_id)[1]; (Too lag)
// dataList = [];
// for (let i = 0; i < labelList.length; i++) {
//   // var serverData = dataWithDT.find(dt => dt[0].getTime() == labelList[i].getTime()); (Too lag)
//   var serverData = dataWithDTObj[labelList[i].getTime()];
//   dataList.push(serverData ? serverData : null);
// } -->

<!-- {
  // title: {
  //   display: true,
  //   text: 'Chart.js Line Chart - Multi Axis'
  // }
  // labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  // datasets: [
  // {
  //   label: 'Dataset 1',
  //   data: [40, 39, 10, 40, 39, 80, 40],
  //   borderColor: '#f87979',
  //   backgroundColor: '#f87979',
  //   yAxisID: 'y',
  // },
  // {
  //   label: 'Dataset 2',
  //   data: this.shuffle([40, 39, 10, 70, 39, 80, 20]),
  //   borderColor: '#0eed4a',
  //   backgroundColor: '#0eed4a',
  //   yAxisID: 'y1',
  // }
  // ]
} 


// var inputPrep = {
//   fy: this.startDTObj.getFullYear(),
//   fm: this.startDTObj.getMonth() + 1,
//   fd: this.startDTObj.getDate(),
//   fh: this.startDTObj.getHours(),
//   fmin: this.startDTObj.getMinutes(),

//   ty: maxEndDTObj.getFullYear(),
//   tm: maxEndDTObj.getMonth() + 1,
//   td: maxEndDTObj.getDate(),
//   th: maxEndDTObj.getHours(),
//   tmin: maxEndDTObj.getMinutes(),
//   points: trendPointList
// }




// var devicesDropDown = [];
//      console.log(this.savedDataSets)
// this.savedDataSets.forEach(ds => {
//   var usages = ds.currDevice.usage;
//   if (usages == 'ac') usages = 'temp';
//   if (usages == 'dehumi') usages = 'humi';
//   var isOthers = this.currentScreen == 'others';
//   var sameUsageCurrentScreen = this.currentScreen == usages;
//   var isSpecificUsage = this.allUsages.includes(usages);
//   if ((isOthers && !isSpecificUsage) || (!isOthers && sameUsageCurrentScreen)) {
//      console.log(this.devicesDropDownDisplay)



  // if (isFirstFewPointsChecked) dev.chartChecked = i < 4;
  //        console.log(dev.currDevice)
  // var canAddDropDown = true;
  // devicesDropDown.forEach(devDd => {
  //   if (devDd.key_id == dev.currDevice.key_id) {
  //     canAddDropDown = false; return;
  //   }
  // });
  // if (canAddDropDown) devicesDropDown.push(dev.currDevice);
-->