<template>
  <div v-if="true" class="pop-up edit-action edit-condition">
    <PopUpHead :details="head_detail" rightBtnTxt="done" :leftArr="true" :hideCross="true" :wholeScreen="true"
      :isSamePageHide="true" />
    <div class="action-title">
    </div>
    <div class="main-content can-scroll">
      <PopUpToast />
      <SelectDropDown class="new-title" mainLabel="select_device" :items="allowAddPointList" :hideCheckBox="true"
        :isCloseOutside="true" :currentPoint="currentPoint" :isCondDevice="true" />
      <SelectDropDown v-if="Object.keys(currentPoint).length !== 0" class="new-title" mainLabel="no_condition"
        :items="conditionType[currentPoint.type]" :hideCheckBox="true" :currentCond="clonedDetails.type" :isCond="true"
        :hideIcon="true" />
      <div v-if="clonedDetails.type == 'stat'" class="frame vertical-center cond-stat">
        <BtnToggle leftSwitchString="=" rightSwitchString="≠" :leftSwitchSelected="isEqualSwitchSelected"
          itemToChange="equalLeft" />
        <BtnToggle leftSwitchString="on" rightSwitchString="off" :leftSwitchSelected="isOnSwitchSelected"
          itemToChange="statRight" />
      </div>
      <!-- mode -->
      <div v-if="clonedDetails.type == 'mode'" class="frame vertical-center cond-stat">
        <BtnToggle leftSwitchString="=" rightSwitchString="≠" :leftSwitchSelected="isEqualSwitchSelected"
          itemToChange="equalLeft" />
        <div class="frame option-button mode" @click="modeClicked" role="button">
          <img :src="modeIcon.option[clonedDetails.value.fix]" class="onoff-icon" />
        </div>
      </div>

      <!-- room temperature & value -->
      <div v-if="clonedDetails.type == 'rt' || clonedDetails.type == 'value'" class="temp">
        <div class="frame vertical-center cond-stat">
          <div class="vertical-center">
            <input class="form-check-input" type="checkbox" :checked="isRTFixedChecked"
              @change="checkboxChanged('RTFixed', $event)">
            <BtnToggle leftSwitchString=">" rightSwitchString="<" :leftSwitchSelected="isFixedBiggerSwitchSelected"
              itemToChange="biggerLeft" />
          </div>
          <div class="vertical-center">
            <!-- {{ currentPoint }} -->
            <input v-if="isTemp(clonedDetails.mp)" class="input-unit" type="number" v-model="fixValue" />
            <input v-else class="input-unit" type="number" v-model="clonedDetails.value.fix" />
            <!-- <input class="input-unit" type="number" v-model="clonedDetails.value.fix" /> -->
            <div v-if="clonedDetails.type == 'rt' || currentPoint.unit == 'DEG'">{{ this.stringTable.word('DEG') }}</div>
            <div v-else>{{ currentPoint.unit }}</div>
          </div>
        </div>
        <div class="frame vertical-center cond-stat">
          <div class="vertical-center">
            <input class="form-check-input" type="checkbox" :checked="isRTOfsetChecked"
              @change="checkboxChanged('RTOfset', $event)">
            <BtnToggle leftSwitchString=">" rightSwitchString="<" :leftSwitchSelected="isOfsetBiggerSwitchSelected"
              itemToChange="biggerLeft" />
          </div>
          <div class="vertical-center">
            <SelectDropDown class="new-title" mainLabel="select_device" :items="allowAddPointListForRT"
              :hideCheckBox="true" :currentPoint="commModule.point_list[clonedDetails.value.mp]" :isRTCond="true"
              :isCloseOutside="true" />
            {{ "+" }}
            <input class="input-unit" type="number" v-model="ofsetValue" />
            {{ clonedDetails.value.mp ? displayOfsetUnit(clonedDetails.value.mp) : '' }}
          </div>
        </div>
      </div>

      <!-- error & alert -->
      <div v-if="clonedDetails.type == 'error' || clonedDetails.type == 'alert'" class="frame vertical-center cond-stat">
        <BtnToggle leftSwitchString="=" rightSwitchString="≠" :leftSwitchSelected="isEqualSwitchSelected"
          itemToChange="equalLeft" />
        <BtnToggle leftSwitchString="occur" rightSwitchString="clear" :leftSwitchSelected="isOccurSwitchSelected"
          itemToChange="occurRight" />
      </div>

    </div>
  </div>
</template>

<style>
.edit-condition .select-dropdown-holder .can-scroll {
  max-width: unset;
}

.edit-condition .select-dropdown-holder .dropdown-menu {
  width: 400px;
}

.edit-condition .form-check-input {
  margin-right: 10px;
}

.edit-condition .main-content {
  height: 540px;
}

.edit-condition .temp .new-title {
  height: 35px;
  width: 400px;
  margin-right: 10px;
}

.edit-condition .temp .point-select {
  padding: 5px;
}

.edit-condition .input-unit {
  text-align: center;
  background-color: var(--inputArea);
  margin: 2px 10px 2px 10px;
  height: 24px !important;
  width: 100px;
}

.edit-condition .option-button.mode {
  padding: 5px;
}

.edit-condition .cond-stat,
.interlock-action .cond-stat {
  padding: 0px 15px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.edit-condition .new-title {
  margin-bottom: 15px;
}
</style>

<script>
import SelectDropDown from './SelectDropDown.vue';
import BtnToggle from './BtnToggle.vue';
import PopUpHead from './PopUpHead.vue'
import PopUpToast from './PopUpToast.vue';

export default {
  name: "InterlockEditCond",
  data() {
    return {
      nameDetails: { name: "edit_cond" },
      pointIdNameList: [],
      currentPoint: {},
      RTCondPoint: {},
      allowAddPointList: [],
      conditionType: {
        'Status': ['stat', 'error', 'alert'],
        'Switch': ['stat', 'error', 'alert'],
        'Value': ['value'],
        'Level': ['value'],
        'LevelSw': ['stat', 'value'],
        // 'MultiStat': ['mstat'], // no condition yet
        // 'Selection': ['mstat'], // no condition yet
        'Ac': ['stat', 'mode', 'rt', 'error', 'alert'],
        'Apu': ['stat', 'error'],
        'Hrv': ['stat'],// no condition yet
        // 'Rgbw': ['stat'],// no condition yet
        // 'Lock': ['alert', 'lock'],// no condition yet
        // 'SensorStat': ['alert', 'stat'],// no condition yet
        // 'Occupied': ['statr'],// no condition yet
        'Dehumi': ['stat', 'error', 'alert', 'value'],
        'Alert': ['alert'],
        'Error': ['error'],
      },
      modeIcon: {
        option: {
          'C': this.ReiriIcons.cooling,
          'H': this.ReiriIcons.sun,
          'F': this.ReiriIcons.fan,
          'D': this.ReiriIcons.dry,
          'A': this.ReiriIcons.auto_colour,
          'T': this.ReiriIcons.air_conditioning,
        },
      },
      clonedDetails: {},
      isRTFixedChecked: false,
      isRTOfsetChecked: false,
      isFixedBiggerSwitchSelected: false,
      isOfsetBiggerSwitchSelected: false,
      toast: {},
      isError: false,
      allowAddPointListForRT: [],
    }
  },
  props: {
    commModule: Object,
    details: Object,
    actionDetails: Object,
    showLast: Boolean,
    isNewCond: Boolean,
    selectedPoint: Object,
    index: Number,
    allConditions: Array,
  },
  created() {
    if (this.isNewCond) {
      this.clonedDetails = { 'mp': '', 'type': '', 'condition': '', 'value': {} }
      this.currentPoint = {}
    }
    else {
      this.clonedDetails = JSON.parse(JSON.stringify(this.details))
      this.currentPoint = this.selectedPoint
      this.isRTFixedChecked = !('mp' in this.clonedDetails.value)
      this.isRTOfsetChecked = 'mp' in this.clonedDetails.value
      this.isFixedBiggerSwitchSelected = this.isRTFixedChecked && this.clonedDetails.condition == '>'
      this.isOfsetBiggerSwitchSelected = this.isRTOfsetChecked && this.clonedDetails.condition == '>'
      // this.clonedDetails.type = this.details.type
    }
    this.generateAllowAddPointList()
    this.allowAddPointListForRT = this.generateAllowAddPointRTList()
  },
  watch: {
  },
  computed: {
    fixValue: {
      get() {
        if (this.clonedDetails.value.fix == '')
          return ''
        else
          return this.tempConvert.tempConvert(this.clonedDetails.value.fix)
      },
      set(value) {
        this.clonedDetails.value.fix = this.tempConvert.tempRevConvert(value)
      }
    },
    ofsetValue: {
      get() {
        if (this.clonedDetails.value.ofset == '')
          return ''
        else
          return this.tempConvert.tempDiffConvert(this.clonedDetails.value.ofset)
      },
      set(value) {
        this.clonedDetails.value.ofset = this.tempConvert.tempDiffRevConvert(value)
      }
    },
    currentPointId() {
      return this.clonedDetails.mp
    },
    toastType() {
      return ''; // Default type if not toDelete
    },
    isOccurSwitchSelected() {
      return this.clonedDetails.value.fix == true
    },
    isBiggerSwitchSelected() {
      return this.clonedDetails.condition == '>'
    },
    isOnSwitchSelected() {
      return this.clonedDetails.value.fix == 'on'
    },
    isEqualSwitchSelected() {
      return this.clonedDetails.condition == '='
    },
    head_detail() {
      var name = this.isNewCond ? this.stringTable.word("add_cond") : this.stringTable.word(this.nameDetails.name)
      return { name }
    },
    modeList() {
      return Object.keys(this.modeIcon.option)
    },
    currentMode() {
      return this.clonedDetails.value.fix
    }
  },
  methods: {
    isTemp(targetKeyId) {
      const pointObj = this.commModule.point_list[targetKeyId]
      if (pointObj == null)
        return false
      else if (Object.prototype.hasOwnProperty.call(pointObj, 'unit')) {
        return pointObj.unit == 'DEG' ? true : false
      } else if (pointObj.usage == 'ac') {
        return true
      } else {
        return false
      }
    },
    displayOfsetUnit(targetKeyId) {
      const pointObj = this.commModule.point_list[targetKeyId]
      if (pointObj == null)
        return ''
      else if (Object.prototype.hasOwnProperty.call(pointObj, 'unit')) {
        return pointObj.unit == 'DEG' ? this.stringTable.word('DEG') : pointObj.unit
      } else if (pointObj.usage == 'ac') {
        return this.stringTable.word('DEG')
      } else {
        return ''
      }
    },
    checkboxChanged(inputId, event) {
      if (inputId === 'RTFixed') {
        this.isRTFixedChecked = event.target.checked;
        if (event.target.checked)
          this.isRTOfsetChecked = false
      }
      else if (inputId === 'RTOfset') {
        this.isRTOfsetChecked = event.target.checked;
        if (event.target.checked)
          this.isRTFixedChecked = false
      }

    },
    changeBtnToggle(item, value) {
      if (item == 'statRight') {
        this.clonedDetails.value.fix = value ? 'on' : 'off'
      } else if (item == 'equalLeft') {
        this.clonedDetails.condition = value ? '=' : '!='
      } else if (item == 'biggerLeft') {
        this.clonedDetails.condition = value ? '>' : '<'
      } else if (item == 'occurRight')
        this.clonedDetails.value.fix = value ? true : false
    },
    modeClicked() {
      var index = this.modeList.indexOf(this.clonedDetails.value.fix)
      // var index = this.modeList.indexOf(this.clonedDetails.value.fix)
      this.clonedDetails.value.fix = (index + 1 >= this.modeList.length) ? this.modeList[0] : this.modeList[index + 1]
    },
    formatNumberWithDecimalPlaces(value, decimalPlaces) {
      // Convert the value to a string with the specified number of decimal places
      const formattedValue = value.toFixed(decimalPlaces);

      // Convert the formatted string back to a number
      const result = parseFloat(formattedValue);

      return result;
    },
    clickRightBtn() {
      var finalDetails = JSON.parse(JSON.stringify(this.clonedDetails))

      const isCondIncluded = this.allConditions.some(item => {
        return (
          JSON.stringify(item.mp) === JSON.stringify(finalDetails.mp) &&
          JSON.stringify(item.type) === JSON.stringify(finalDetails.type) &&
          JSON.stringify(item.condition) === JSON.stringify(finalDetails.condition) &&
          JSON.stringify(item.value) === JSON.stringify(finalDetails.value)
        );
      });

      if (isCondIncluded) {
        this.toast = {
          text: 'same_cond',
          isError: true
        }
        return
      }

      if (this.clonedDetails.mp == '') {
        this.toast = {
          text: 'no_device',
          isError: true
        }
        return
      }
      if (this.clonedDetails.type == '') {
        this.toast = {
          text: 'no_condition',
          isError: true
        }
        return
      }
      if (this.clonedDetails.type == 'rt' || this.clonedDetails.type == 'value') {
        if (!this.isRTFixedChecked && !this.isRTOfsetChecked) {
          this.toast = {
            text: 'no_condition',
            isError: true
          }
          return
        } else if (this.isRTFixedChecked) {
          if (finalDetails.value.fix == '' && finalDetails.value.fix !== 0) {
            this.toast = {
              text: 'invalid_input',
              isError: true
            }
            return
          }
          if (Object.prototype.hasOwnProperty.call(finalDetails.value, 'mp'))
            delete finalDetails.value['mp']
          if (Object.prototype.hasOwnProperty.call(finalDetails.value, 'ofset'))
            delete finalDetails.value['ofset']
          // convert value to double
          // if (finalDetails.value.fix != '')
          // finalDetails.value.fix = this.formatNumberWithDecimalPlaces(finalDetails.value.fix, 1)
        } else if (this.isRTOfsetChecked) {
          if (!('mp' in finalDetails.value)) {
            this.toast = {
              text: 'pls_sel_dev_cap',
              isError: true
            }
            return
          } else if (finalDetails.value.ofset == '' && finalDetails.value.ofset !== 0) {
            this.toast = {
              text: 'invalid_input',
              isError: true
            }
            return
          }
          if (Object.prototype.hasOwnProperty.call(finalDetails.value, 'fix')) {
            delete finalDetails.value['fix']
            // convert value to double
            // if (finalDetails.value.ofset != '')
            // finalDetails.value.ofset = this.formatNumberWithDecimalPlaces(finalDetails.value.ofset, 1)
          }
        }
      }
      this.$parent.updateCondDetails(finalDetails, this.index)
      this.back()
    },
    generateAllowAddPointList() {
      const targetLi = this.MixList.get(['point']);
      this.allowAddPointList = targetLi.filter((item) => {
        return Object.keys(this.conditionType).includes(item.type);
      });
    },
    generateAllowAddPointRTList() {
      const points = JSON.parse(JSON.stringify(this.allowAddPointList));
      var finalPoints = points.filter((item) => {
        return item.key_id !== this.currentPointId && !['stat', 'switch', 'dehumi', 'hrv'].includes(this.commModule.point_list[item.key_id].usage);
      });
      return finalPoints;
    },
    changeOption(newValue, isCondition, isRtCondition) {
      if (newValue) {
        if (isRtCondition)
          this.clonedDetails.value.mp = newValue.key_id
        else if (isCondition) {
          this.clonedDetails.type = newValue
          if (newValue == 'stat') {
            this.clonedDetails.value.fix = 'off'
            this.clonedDetails.condition = '!='
          }
          else if (newValue == 'mode') {
            this.clonedDetails.value.fix = 'C'
            this.clonedDetails.condition = '!='
          }
          else if (newValue == 'value' || newValue == 'rt') {
            this.clonedDetails.condition = '<'
            this.clonedDetails.value.fix = ''
            this.clonedDetails.value.ofset = ''
            this.allowAddPointListForRT = this.generateAllowAddPointRTList()
          }
          else {
            this.clonedDetails.condition = '!='
            this.clonedDetails.value.fix = false
          }
        }
        else {
          this.currentPoint = newValue
          this.clonedDetails = { 'mp': newValue.key_id, 'type': '', 'condition': '', 'value': {} }
        }
      }
    },
    back() {
      this.$parent.showInterlockEditCond = false
    },
  },
  components: {
    SelectDropDown,
    BtnToggle,
    PopUpHead,
    PopUpToast,
  }
}
</script>