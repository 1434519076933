<template>
  <div class="pop-up edit-action interlock-action bar-droppable">
    <PopUpHead :details="head_detail" rightBtnTxt="done" :isSamePageHide="true" :hideBorderBtm="false" :leftArr="true"
      :hideCross="true" :wholeScreen="true" />
    <div class="action-title">
    </div>
    <div class="main-content can-scroll">
      <PopUpToast />
      <div v-if="isNewAction" class="vertical-center action-type-title">
        <!-- {{ currentGroupPointTypeList }} -->
        <div v-for="(item, index) in actionType" :key="index" class="action-type" role="button"
          :class="{ 'to-active': selectedTypeIndex == index }" @click="selectType(index)">
          {{ item == 'point' ? stringTable.word("dev") : stringTable.word(item) }}
        </div>
      </div>
      <div v-if="isNewAction" class="vertical-center sel-device">
        <div>
          {{ stringTable.word("select") + ' ' + (actionType[selectedTypeIndex] == 'point' ? stringTable.word("dev") :
            stringTable.word(actionType[selectedTypeIndex])) + ':' }}
        </div>
        <!-- {{ dropDownList[selectedTypeIndex] }} -->
        <SelectDropDown class="new-title" mainLabel="select_device" :items="dropDownList[selectedTypeIndex]"
          :hideCheckBox="true" :isCloseOutside="true" :currentPoint="currentAction" />
      </div>
      <!-- {{ updatedInterlockActionList }} -->
      <!-- Device -->
      <div v-if="Object.keys(currentAction).length !== 0 && selectedTypeIndex == 0">
        <DroppableControl v-for=" (value, key) in updatedInterlockActionList[currentAction.type]" :key="key"
          :actionValue="key" :currentPoint="currentAction" :detailsList="updatedInterlockActionList" />
      </div>
      <!-- Group -->
      <div v-if="Object.keys(currentAction).length !== 0 && selectedTypeIndex == 1 && currentAction.mp.length != 0">
        <DroppableControl v-for=" ( value, key ) in updatedInterlockActionList[currentAction.icon_map] " :key="key"
          :actionValue="key" :currentPoint="currentAction" :detailsList="updatedInterlockActionList"
          :isGroupAction="true" :isHrvGroup="isHrvGroup" />
      </div>

      <!-- Scene, Schedule, Interlock -->
      <div v-else-if="Object.keys(currentAction).length !== 0 && [2, 3, 4].includes(this.selectedTypeIndex)"
        class="vertical-center cond-stat frame">
        <div>
          {{ stringTable.word(actionType[selectedTypeIndex]) }}
        </div>
        <div class="frame option-button with-txt" role="button"
          :class="{ 'activebg': actionOn, 'centre-icon': selectedTypeIndex == 2 }" @click="oneOptionClicked">
          <img :src="selectedTypeIndex == 2 ? ReiriIcons.execute : ReiriIcons.circle" class="onoff-icon"
            :class="{ 'to-white ': actionOn }" />
          <div v-if="selectedTypeIndex == 3 || selectedTypeIndex == 4" class="icon-title">
            {{ actionOn ? stringTable.word("enable") : stringTable.word("disable") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.interlock-action .icon-title {
  font-size: 10px;
}

.interlock-action .cond-stat .onoff-icon {
  margin: auto 0;
}

.interlock-action .cond-stat .option-button {
  margin: 8px 0px;
  height: 55px;
}

.interlock-action .centre-icon {
  display: flex;
  align-items: center;
}

.interlock-action .bottom-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px !important;
}

.interlock-action .main-content {
  height: 540px;
}

.interlock-action .new-title {
  height: 35px;
  width: 450px;
  margin: unset;
  margin-left: 10px;
}

.interlock-action .point-select {
  padding: 5px !important;
}

.interlock-action .to-active {
  border-bottom: 2px solid var(--active);
}

.interlock-action .action-type {
  width: 120px;
  height: 30px;
  justify-content: center;
  display: flex;
}

.interlock-action .sel-device {
  margin: 8px 0;
  justify-content: center;
  display: flex;
}

.interlock-action .action-type-title {
  height: 50px;
  justify-content: center;
  display: flex;
}
</style>

<script>
import DroppableControl from "./DroppableControl.vue";
import SelectDropDown from './SelectDropDown.vue';
import PopUpToast from './PopUpToast.vue';
import PopUpHead from './PopUpHead.vue'

export default {
  name: "InterlockEditAction",
  data() {
    return {
      nameDetails: { name: "edit_action" },
      actionType: ["point", "group", "scene", "schedule", "interlock"],
      selectedTypeIndex: 0,
      allowAddPointList: [],
      allowAddGroupList: [],
      allowAddSceneList: [],
      allowAddScheduleList: [],
      allowAddInterlockList: [],
      dropDownList: [],
      currentAction: {},
      updatedInterlockActionList: {},
      defaultInterlockActionList: {
        Switch: { stat: { value: 'off' }, off_timer: { value: 'off' } },
        Level: { av: { value: 0 } },
        LevelSw: { stat: { value: 'off' }, off_timer: { value: 'off' }, av: { value: 0 } },
        Ac: {
          stat: { value: 'off' }, off_timer: { value: 'off' }, mode: { value: 'C' },
          sp: { value: 24 }, fanstep: { value: 'L' }, sb_csp: { value: 28 }, sb_hsp: { value: 18 },
          rc_proh: {
            value: [],
            option: {
              rop_proh: { value: false, name: 'stat' }, rmode_proh: { value: false, name: 'mode' },
              rsp_proh: { value: false, name: 'sp' }, rfan_proh: { value: false, name: 'fanstep' }
            }
          }
        },
        Apu: {
          stat: { value: 'off' }, off_timer: { value: 'off' }, apu_mode: { value: 'MA' }, fanstep: { value: 'L' }
        },
        Hrv: {
          stat: { value: 'off' }, off_timer: { value: 'off' }, vmode: { value: 'A' }, vamount: { value: 'A' },
          rc_proh: {
            value: [],
            option: {
              rop_proh: { value: false, name: 'stat' }
            }
          }
        },
        Rgbw: { stat: { value: 'off' }, off_timer: { value: 'off' }, rgbw: { value: 0 } },
        Shutter: { shutter: { value: 'close' } },
        Lock: { lock: { value: 'close' } },
        Dehumi: {
          stat: { value: 'off' }, off_timer: { value: 'off' }, fanstep: { value: 'L' },
          hum_sp: { value: 50 }
        }
      },
      actionOn: true,
      typesToHide: ['Status', 'Value', 'Meter', 'SMeter', 'MultiStat', 'Sslection', 'SensorStat', 'Occupied'],
      clonedDetails: [],
      remoteProhibitionList: ['rop_proh', 'rmode_proh', 'rsp_proh', 'rfan_proh'],
      spRelatedKeys: ['sp', 'sb_csp', 'sb_hsp'],
      toast: {},
      currentGroupPointTypeList: [],
      currentGroupActionList: {},
      isHrvGroup: false,
    }
  },
  props: {
    commModule: Object,
    isNewAction: Boolean,
    details: Object,
    selectedAction: Object,
    type: Object,
    index: Number,
    actionKeyList: Array,
    allActions: Array,
  },
  created() {
    this.generateAllowAddPointList()
    this.allowAddGroupList = this.MixList.get(['group'])
    this.allowAddSceneList = this.MixList.get(['scene'])
    this.allowAddScheduleList = this.MixList.get(['schedule'])
    this.allowAddInterlockList = this.MixList.get(['interlock'])
    this.dropDownList = [this.allowAddPointList, this.allowAddGroupList, this.allowAddSceneList, this.allowAddScheduleList, this.allowAddInterlockList]
    if (this.isNewAction) {
      this.clonedDetails = []
      this.currentAction = {}
    } else {
      this.currentAction = this.selectedAction
      this.clonedDetails = JSON.parse(JSON.stringify(this.details))
      this.selectedTypeIndex = this.actionType.indexOf(this.type)
      if (this.selectedTypeIndex == 0) { // device
        this.generateInterlockActionDetails()

        if (this.currentAction.type == 'Hrv') {
          this.remoteProhibitionList = ['rop_proh']
        } else
          this.remoteProhibitionList = ['rop_proh', 'rmode_proh', 'rsp_proh', 'rfan_proh']
      }
      else if (this.selectedTypeIndex == 1) { // group
        // this.actionOn = this.clonedDetails[1].exec
        this.generateGroupActionList()
        this.generateInterlockActionDetails()
      } else if (this.selectedTypeIndex == 2) {  // scene
        this.actionOn = this.clonedDetails[1].exec
      } else { // schedule, interlock
        this.actionOn = this.clonedDetails[1].enable
      }
    }
  },
  watch: {
    selectedTypeIndex() {
      if (this.isNewAction) {
        this.currentAction = {}
        this.actionOn = false
        this.clonedDetails = []
      }
    }
  },
  computed: {
    toastType() {
      return ''; // Default type if not toDelete
    },
    head_detail() {
      var name = this.isNewAction ? this.stringTable.word("add_action") : this.stringTable.word(this.nameDetails.name)
      return { name }
    },
  },
  methods: {
    showRcProh() {
      var toShow = false
      this.currentAction.mp.forEach(point_id => {
        var point = this.commModule.point_list[point_id];
        if (point && point.rc_proh_cap) {
          toShow = true
        }
      })
      return toShow
    },
    checkIfGroupContainsOnlyHrv(group) {
      for (let i = 0; i < group.mp.length; i++) {
        const mp = group.mp[i];
        const point = this.commModule.point_list[mp];
        if (point && point.type !== 'Hrv') {
          // If the point is not of type 'Hrv', return false
          return false;
        }
      }
      // If all points are of type 'Hrv', return true
      return true;
    },
    generateInterlockActionDetails() {
      const pointType = this.currentAction.type // switch, level, ac ...
      if (!this.selectedTypeIndex == 1)
        this.updatedInterlockActionList = JSON.parse(JSON.stringify(this.defaultInterlockActionList))
      for (const key in this.clonedDetails[1]) {
        if (this.remoteProhibitionList.includes(key)) {
          if (this.clonedDetails[1][key]) {
            if (this.selectedTypeIndex == 0) {
              if (pointType == 'Hrv') {
                this.updatedInterlockActionList['Hrv']['rc_proh'].value.push(this.updatedInterlockActionList['Hrv']['rc_proh'].option[key].name)
              } else
                this.updatedInterlockActionList['Ac']['rc_proh'].value.push(this.updatedInterlockActionList['Ac']['rc_proh'].option[key].name)
            }
            else if (this.selectedTypeIndex == 1) {
              this.updatedInterlockActionList['group']['rc_proh'].value.push(this.updatedInterlockActionList['group']['rc_proh'].option[key].name)
            }
            // do group here?
          }
          this.updatedInterlockActionList[this.selectedTypeIndex == 0 ? this.currentAction.type : 'group']['rc_proh'].toShow = true
          this.updatedInterlockActionList[this.selectedTypeIndex == 0 ? this.currentAction.type : 'group']['rc_proh'].option[key].value = this.clonedDetails[1][key]
          this.updatedInterlockActionList[this.selectedTypeIndex == 0 ? this.currentAction.type : 'group']['rc_proh'].option[key].toShow = true
        } else {
          if (Object.prototype.hasOwnProperty.call(this.updatedInterlockActionList[this.selectedTypeIndex == 0 ? this.currentAction.type : 'group'], key)) {
            this.updatedInterlockActionList[this.selectedTypeIndex == 0 ? this.currentAction.type : 'group'][key].value = this.clonedDetails[1][key]
            this.updatedInterlockActionList[this.selectedTypeIndex == 0 ? this.currentAction.type : 'group'][key].toShow = true
          }
        }
      }
      // pointType == 'Ac'
      if (!this.currentAction.rc_proh_cap && 'rc_proh_cap' in this.currentAction)
        delete this.updatedInterlockActionList[pointType].rc_proh;
      if (!this.currentAction.sp_cap && 'sp_cap' in this.currentAction)
        this.spRelatedKeys.forEach(key => delete this.updatedInterlockActionList[pointType][key])
      if (this.currentAction.fanstep_cap && this.currentAction.fanstep_cap.S == 1 && 'fanstep_cap' in this.currentAction)
        delete this.updatedInterlockActionList[pointType].fanstep;
    },
    oneOptionClicked() {
      this.actionOn = this.actionOn ? false : true;
      if (this.selectedTypeIndex == 2)
        this.clonedDetails[1].exec = this.actionOn
      else if ([3, 4].includes(this.selectedTypeIndex))
        this.clonedDetails[1].enable = this.actionOn
    },
    selectType(index) {
      this.selectedTypeIndex = index
    },
    clickRightBtn() {
      if (this.clonedDetails.length === 0) {
        this.toast = { text: 'no_action', isError: true }
        return;
      }
      var finalDetails = JSON.parse(JSON.stringify(this.clonedDetails))

      if (this.selectedTypeIndex == 0 || this.selectedTypeIndex == 1) {
        finalDetails[1] = this.filterToShowProperties(this.updatedInterlockActionList[this.selectedTypeIndex == 0 ? this.currentAction.type : 'group'])
        if (Object.keys(finalDetails[1]).length === 0) {
          this.toast = { text: 'no_action', isError: true }
          return;
        }
      }

      var isPassed = true;
      for (let i = 0; i < this.allActions.length; i++) {
        if (i == this.index) continue;
        const action = this.allActions[i];
        if (finalDetails[0] == action[0])
          isPassed = false;
      }
      // if (JSON.stringify(this.allActions).includes(JSON.stringify(finalDetails))) {
      if (!isPassed) {
        this.toast = {
          text: 'target_alrdy_selected',
          isError: true
        }
        return
      }
      this.$parent.updateActionDetails(finalDetails, this.index)
      this.back()
    },
    filterToShowProperties(input) {
      const filteredObject = {};
      for (const key in input) {
        if (key == 'rc_proh' && input[key].toShow) {
          this.remoteProhibitionList.forEach(key => {
            filteredObject[key] = input['rc_proh'].option[key].value
          })
        } else
          if (Object.prototype.hasOwnProperty.call(input, key) && input[key].toShow) {
            filteredObject[key] = input[key].value;
          }
      }
      return filteredObject;
    },
    generateAllowAddPointList() {
      this.allowAddPointList = { ...this.commModule.point_list }
      this.updatedInterlockActionList = JSON.parse(JSON.stringify(this.defaultInterlockActionList))

      this.allowAddPointList = Object.keys(this.allowAddPointList)
        .map(key => this.allowAddPointList[key]);

      //      console.log(this.allowAddPointList)
      // this.allowAddPointList = Object.fromEntries(
      //   Object.entries(this.allowAddPointList).filter(([, value]) => !this.typesToHide.includes(value.type))
      // );
      this.allowAddPointList = this.allowAddPointList.filter(item => !this.typesToHide.includes(item.type));

    },
    generateGroupActionList() {
      this.currentGroupPointTypeList = this.currentAction.mp.map(pointKey =>
        this.MixList.commModule.point_list[pointKey] ? this.MixList.commModule.point_list[pointKey].type : ''
      )
      if (JSON.stringify(this.currentGroupPointTypeList) === JSON.stringify(['Hrv'])) {
        this.isHrvGroup = true
        this.remoteProhibitionList = ['rop_proh']
      } else {
        this.isHrvGroup = false
        this.remoteProhibitionList = ['rop_proh', 'rmode_proh', 'rsp_proh', 'rfan_proh']
      }
      for (const pointType of this.currentGroupPointTypeList) {
        this.currentGroupActionList = {
          ...this.currentGroupActionList,
          ...this.defaultInterlockActionList[pointType]
        };
      }

      // if group contain ac, set rcproh to all 4 option
      if (this.currentGroupPointTypeList.includes('Ac'))
        this.currentGroupActionList['rc_proh'] = this.defaultInterlockActionList['Ac']['rc_proh']

      if (this.showRcProh() == false || !this.currentGroupPointTypeList.includes('Ac'))
        delete this.currentGroupActionList['rc_proh']

      var showSetpoint = false;
      var showFanstep = false;
      this.currentAction.mp.forEach(point_id => {
        var point = this.commModule.point_list[point_id];
        if (point) {
          if (point.sp_cap) showSetpoint = true;
          if (point.fanstep_cap && point.fanstep_cap.S != 1) showFanstep = true;
        }
      });
      if (!showSetpoint) this.spRelatedKeys.forEach(key => delete this.currentGroupActionList[key]);
      if (!showFanstep) delete this.currentGroupActionList.fanstep;
      this.updatedInterlockActionList['group'] = this.currentGroupActionList
    },
    changeOption(newValue) {
      if (newValue) {
        switch (newValue.icon_map) {
          case 'point':
            this.currentAction = this.MixList.commModule.point_list[newValue.key_id]
            if (this.currentAction.type == 'Hrv') {
              this.remoteProhibitionList = ['rop_proh']
            } else
              this.remoteProhibitionList = ['rop_proh', 'rmode_proh', 'rsp_proh', 'rfan_proh']
            this.generateInterlockActionDetails()
            break;
          case 'group':
            this.currentGroupActionList = {}
            this.currentAction = this.MixList.commModule.group_list[newValue.key_id]
            this.generateGroupActionList()
            break;
          case 'scene':
            this.currentAction = this.MixList.commModule.scene_list[newValue.key_id]
            this.clonedDetails[1] = { 'exec': true }
            this.actionOn = true
            break;
          case 'schedule':
            this.currentAction = this.MixList.commModule.get_schedule.program[newValue.key_id]
            this.clonedDetails[1] = { 'enable': true }
            this.actionOn = true
            break;
          case 'interlock':
            this.currentAction = this.MixList.commModule.interlock_get_program.program[newValue.key_id]
            this.clonedDetails[1] = { 'enable': true }
            this.actionOn = true
            break;
          default:
            break;
        }
      }
      this.clonedDetails[0] = newValue.key_id
    },
    back() {
      this.$parent.showInterlockEditAction = false
    },
  },
  components: {
    PopUpHead,
    DroppableControl,
    SelectDropDown,
    PopUpToast,
  }
}
</script>