<template>
  <div class="pop-up iaq-chart">
    <PopUpHead :details="detailsCopy" :circle="detailsCopy.indicator" />
    <div class="body-content can-scroll">
      <div v-for="detail, index in detailsCopy.details" :key="index" class="bef-frame">
        <div class="frame">
          <div class="top-content vertical-center" @click="clickTop(detail)">
            <div class="circle small" :class="`bkg-${detail.indicator}`"></div>
            <div class="left-content">
              <div class="type font-bold">{{ detail.type_display }}</div>
              <div class="point-name">{{ commModule.point_list[detail.id] ? commModule.point_list[detail.id].name :
                detail.id }}</div>
            </div>
            <div class="right-content vertical-center">
              <ValueUnit :val="detail.av" :unit="detail.unit" size="big" />
              <img :src="ReiriIcons.chevron_down" class="arrow" :class="detail.opened" />
            </div>
          </div>
          <div class="btm-content" v-if="detail.showChart">
            <Chart :name="index" :data="detail.data" :options="detail.options" :plugins="plugins"
              :isLoading="detail.isLoading" :isMountedRefresh="true" />
          </div>
        </div>
        <div class="btm-line"></div>
      </div>
    </div>
    <!-- {{ details }} -->
  </div>
</template>

<style>
.pop-up.iaq-chart .body-content {
  padding: 12px 30px;
  height: 540px;
}

.pop-up.iaq-chart .body-content .frame {
  padding: 6px 12px;
}

.pop-up.iaq-chart .body-content .top-content {
  width: 100%;
}

.pop-up.iaq-chart .body-content .type,
.pop-up.iaq-chart .body-content .point-name {
  line-height: 1.1;
}

.pop-up.iaq-chart .body-content .point-name {
  font-size: .8em;
}

.pop-up.iaq-chart .body-content .right-content {
  margin-left: auto;
}

.pop-up.iaq-chart .body-content .right-content .arrow {
  margin-left: 6px;
}

.pop-up.iaq-chart .body-content .right-content .arrow.opened {
  transform: rotate(180deg);
}

.pop-up.iaq-chart .body-content .btm-content {
  margin-top: 8px;
}

.pop-up.iaq-chart .body-content .btm-content .loading-container {
  width: 100%;
  padding-bottom: 18px;
  position: relative;
}

.pop-up.iaq-chart .body-content .btm-content .chart-holder {
  height: 200px;
}

.pop-up.iaq-chart .btm-line {
  background-color: var(--inactive);
  width: 80%;
  height: 1px;
  margin: 8px auto;
}
</style>

<script>
import PopUpHead from "./PopUpHead.vue";
import ValueUnit from './ValueUnit.vue'
import Chart from './Chart.vue'

export default {
  name: "PopUpIaqChart",
  props: {
    commModule: Object,
    details: Object
  },
  data() {
    return {
      detailsCopy: this.details,
      startDt: new Date(),
      endDt: new Date(),
      loadingRoom: {},
      loadedTrendType: false,

      plugins: {
        legend: { display: false }
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          x: {
            grid: { display: false },
          },
          y: {
            title: {
              display: true,
              text: '',
            },
            ticks: { stepSize: 2 },
            type: 'linear',
            lineThickness: 1,
            display: true,
            position: 'left',
          },
        }
      }
    }
  },
  created() {
    this.startDt = new Date();
    this.startDt.setHours(0);
    this.startDt.setMinutes(0);
    this.endDt.setHours(this.endDt.getHours() + 1);
    this.endDt.setMinutes(1);
    this.detailsCopy.details.forEach(roomDetail => {
      roomDetail.opened = '';
      roomDetail.showChart = false;
      roomDetail.data = { datasets: [], labels: [] }
      roomDetail.data.labels = this.labels;
      this.loadingRoom = roomDetail;
      this.loadHighLowLine(roomDetail);
      this.setOption(roomDetail);
    });
    this.commModule.getTrendPoints();
  },
  watch: {
    'commModule.get_trend'(newGetTrend) {
      if (!this.loadedTrendType) return;
      var dates = this.all_dates;
      var roomDetail = this.loadingRoom;
      if (roomDetail) roomDetail.isLoading = false;
      for (const pointId in newGetTrend.data) {
        var data = newGetTrend.data[pointId];
        if (data && Object.keys(data).length > 0) {
          var trendType = this.getTrendType(pointId);
          data = data[trendType];
          var dataObj = {};
          data.forEach(d => {
            dataObj[d[0]] = this.convTemp(d[1]);
          });
          var values = [];
          dates.forEach(date => {
            var dtServer = this.ConvertHelper.dtObjToDtimeServer(date);
            values.push(dataObj[dtServer]);
          })

          var mainColor = this.ColorHelper.get(0);
          roomDetail.data.datasets = [this.getChartData(values, mainColor)];
          roomDetail.data.labels = this.labels;
          this.loadHighLowLine(roomDetail);
          this.setOption(roomDetail, values);
        }
      }
    },
    'commModule.trend_point_list'() {
      this.loadedTrendType = true;
    }
  },
  methods: {
    convTemp(val) {
      return ['temp', 'ac'].includes(this.loadingRoom.type) ? this.tempConvert.tempConvert(val) : val
    },
    getDetail(pointId) {
      return this.detailsCopy.details.find(roomDetail => roomDetail.id == pointId);
    },
    getTrendType(pointId) {
      var trendType = this.commModule.trend_point_list.find((point) => point[0] == pointId);
      if (trendType && trendType.length >= 1) trendType = trendType[1];
      return trendType == 'sp' ? 'temp' : trendType;
    },
    getChartData(data, color) {
      return { data, borderColor: color, backgroundColor: color, borderWidth: 1.8 }
    },
    loadHighLowLine(roomDetail) {
      var datesLen = this.all_dates.length;
      var lowThres = new Array(datesLen).fill(this.convTemp(roomDetail.threshold_yellow));
      var lowColor = '#e8c618';
      var highThres = new Array(datesLen).fill(this.convTemp(roomDetail.threshold_red));
      var highColor = roomDetail.no_red ? lowColor : '#ff6b00';
      roomDetail.data.datasets.push(this.getChartData(highThres, highColor));
      roomDetail.data.datasets.push(this.getChartData(lowThres, lowColor));
    },
    setOption(roomDetail, values) {
      var maxVal = this.ConvertHelper.getMinOrMax(values);
      maxVal = Math.max(maxVal, roomDetail.threshold_yellow, roomDetail.threshold_red);
      var precise = this.ConvertHelper.chartPrecision(maxVal, 8);
      this.options.scales.y.ticks.stepSize = precise;
      roomDetail.options = JSON.parse(JSON.stringify(this.options));
    },
    clickTop(detail) {
      if (!this.loadedTrendType) return;
      var detailses = this.detailsCopy.details;
      for (let index = 0; index < detailses.length; index++)
        if (detailses[index].isLoading) return;

      detail.showChart = !detail.showChart;
      if (detail.showChart) {
        detail.opened = 'opened';
        var pointId = detail.id;//.point.key_id;
        var trendType = this.getTrendType(pointId);
        this.loadingRoom = this.getDetail(pointId);
        this.commModule.getTrend(this.startDt, this.endDt, [[pointId, trendType]]);
        detail.isLoading = true;
      } else detail.opened = '';
    }
  },
  computed: {
    all_dates() {
      var dateLi = [];
      var currDt = new Date(this.startDt);
      while (currDt < this.endDt) {
        dateLi.push(new Date(currDt));
        currDt.setMinutes(currDt.getMinutes() + 5);
      }
      return dateLi;
    },
    labels() {
      var labels = [];
      var dates = this.all_dates;
      dates.forEach((date, index) => {
        if (index == 0 || index == dates.length - 1 ||
          index % ((dates.length - 1) / 4) == 0)
          labels.push(this.ConvertHelper.dtObjToTimeStr(date));
        else labels.push('');
      });
      return labels;
    },
  },
  components: {
    PopUpHead,
    ValueUnit,
    Chart
  }
}
</script>