<template>
  <div class="bar-icon-btn bar-spacing frame vertical-center">
    <div class="label" :style="{ width: ['apu_mode', 'fanstep'].includes(label) ? '100px' : '' }"> {{ stringTable.word(label) }} </div>
    <div class="btn-holder">
      <div v-for="(value, key) in btnIconObj" :key="key" class="btn frame" @click="buttonClicked(key)">
        <div class="vertical-center justify-center">
          <img :src="value" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bar-icon-btn {
  display: flex;
  padding: 4px 6px;
}

/* .bar-icon-btn .label {
  width: 30%;
} */

.bar-icon-btn .btn-holder {
  min-width: 60%;
  max-width: 70%;
  text-align: end;
}

.bar-icon-btn .btn {
  margin: 2px;
  padding: 0;
  width: 38px;
  height: 38px;
}

.bar-icon-btn .btn>div {
  width: 100%;
  height: 100%;
}

.bar-icon-btn .btn img {
  width: 22px;
  height: 22px;
}
</style>

<script>
export default {
  name: "BarIconBtn",
  props: {
    label: String,
    btnIconObj: Object
  },
  methods: {
    buttonClicked(value) {
      this.$parent.barIconBtnClicked(value, this.label)
    }
  }
}
</script>