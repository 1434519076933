import { Account } from './Account.js';
import { ControllerInfo } from './ControllerInfo.js';

////////////////////////////////////////////////
// CommModule 
export class CommModule {
	constructor() {
		this.host = null;
		this.user = null;
		this.passwd = null;
		this.enableSendScene = true

		this.connected = false;
		this.ready = false; // complete login process then true
		this.reason = '';
		this.ws = null;

		this.point_list = null;
		this.group_list = null;
		this.scene_list = null;
		// this.roomIAQ_list = null;

		this.controller = null; // controller information
		this.account = null; // login account information

		this.time = null; // controller time {'date': 'yyyymmdd', 'time':'HHMM'}
		this.app_version = '2.0.3';
		this.json_file = {
			iaq: { file: 'iaq_monitoring_settings.json', var: 'iaq_monitoring_settings' },
			guest: { file: 'guest_room_settings.json', var: 'guest_room_settings' },
			layout: { file: 'layout_list.json', var: 'layout_list' },
		}
	}

	changeVariable(vatstr, val) {
		this[vatstr] = val
	}

	connect(host, user, passwd) {
		this.host = host;
		this.user = user;
		this.passwd = passwd;
		this.reason = '';

		let self = this;	// self is used in inner method to access myself
		let url = "ws://" + this.host + ":" + 52001;

		try {
			if (this.ws == null) {
				this.ws = new WebSocket(url);
				this.ws.onopen = function () {
					self.connected = true;
					let com = ['sys_info', ''];
					self.send(com);	// send login command
				}

				this.ws.onmessage = function (evt) {
					if (self.receive(evt) == false) {
						self.ready = null;
						console.log('Data handling error');
						self.ws.close();
					}
				}

				this.ws.onerror = function () {
					self.ready = null;
					console.log("ERR");
					self.reason = 'connection_err';
					self.ws.close();
				}

				// if connection is closed then move to login screen
				this.ws.onclose = function () {
					console.log('CLOSE SOCK');
					self.closeConnection();
				}
			}
		} catch (e) {
			alert(e);
		}
	}

	send(packet) {
		if (!packet) return;
		try {
			if (this.connected == true) {
				var compiledPacket = [null, null, packet];
				//				console.log('SND: ', compiledPacket);
				let message = JSON.stringify(compiledPacket);
				this.ws.send(message);
				if (packet[0] == 'logout') this.ws.close();
			} else {
				// exec something if connection is closed
			}
		} catch (e) {
			// message send error
			alert(e);
		}
	}

	logout() {
		let com = ['logout'];
		this.send(com);
	}

	// call when command is received from server
	receive(evt) {
		let data = JSON.parse(evt.data);
		let pack = data[2];
		let com;
		// initialization after login
		// try {
		// console.log(pack)
		switch (pack[0]) {
			case 'sys_info':
				// set controller information
				this.controller = new ControllerInfo(pack[1]);
				//				console.log(this.controller)
				// send login command
				com = ['login', { 'name': this.user, 'passwd': this.passwd }]
				this.send(com);
				break;
			case 'login':
				console.log('LOGIN');
				// set login account
				if (pack[1]['result'] == 'OK') {
					this.account = new Account(pack[1]);
					//					console.log(pack)
					this.mode_proh = this.account.auth.mode_proh;
					this.sp_proh = this.account.auth.sp_proh;

					com = ['mplist'];
					this.send(com);
				} else { // login failed
					this.reason = pack[1]['result'];
					console.log(this.reason);
					return false;
				}
				break;
			case 'mplist':
				this.point_list = pack[1];
				for (const key in this.point_list) {
					var point = this.point_list[key];
					point.key_id = key;
					point.icon_map = 'point';
					if (point.fanstep_cap && point.fanstep_cap.S != 0)
						point.fanstep_key = `fanstep${point.fanstep_cap.S}`;
					if (point.flap_cap && point.flap_cap.D != 0)
						point.flap_key = 'flap';
					else if (point.flap2_cap && point.flap2_cap.D != 0)
						point.flap_key = 'flap2';
				}
				com = ['group', ['get_groups']];
				this.send(com);
				break;
			case 'group':
				if (pack[1][0] == 'get_groups') {
					this.group_list = pack[1][1];
					for (const key in this.group_list)
						this.group_list[key].icon_map = 'group';
					//					console.log(this.group_list)
					if (this.enableSendScene) {
						com = ['scene', ['get_scenes']];
						this.send(com);
					}
				} else { // group command handling
					this[`group_${pack[1][0]}`] = pack[1][1];
				}
				break;
			case 'scene':
				this.isAdmin = this.account.ac_class == 'Admin';
				if (pack[1][0] == 'get_scenes') {
					this.scene_list = pack[1][1];
					if (!this.isAdmin) {
						var sceneListfiltered = {};
						for (const key in this.scene_list) {
							const scene = this.scene_list[key];
							if (scene.owner != 'Admin')
								sceneListfiltered[key] = scene;
						}
						this.scene_list = sceneListfiltered;
					}
					for (const key in this.scene_list) {
						this.scene_list[key].key_id = key;
						this.scene_list[key].icon_map = 'scene';
					}
					this.ready = true;
					com = ['read_json', { "name": 'iaq_monitoring_settings.json' }];
					this.send(com);
				} else { // handle scene command
					// this[pack[1][0]] = pack[1][1];
					//					console.log(pack[1][0])
					this[`scene_${pack[1][0]}`] = pack[1][1];

				}
				break;
			case 'cos':
				if ('unit_manage' in pack[1]) {
					this.realTimeGuestRoom = pack[1].unit_manage
					// for (const key in pack[1].unit_manage) {
					// 	this.realTimeGuestRoom = { [key]: pack[1].unit_manage[key] }
					// }
				} else {
					for (let id in pack[1]) {
						if (this.point_list) {
							let point = this.point_list[id];
							if (!point) continue;
							let stat = pack[1][id];
							for (let item in stat) {
								// console.log('COS: ' + id + ' ' + item + ' ' + point[item] + '->' + stat[item]);
								point[item] = stat[item];
							}
						}
					}
				}
				break;
			case 'time':
				// update controller time
				this.time = pack[1];
				console.log(this.time);
				break;
			default:
				if (this.ready) this.command_dispatch(pack);
		}
		return true;
		// } 
		// catch (e) {
		// 	console.log("ERR " + e);
		// 	//		alert(e);
		// 	return false;
		// }
	}

	closeConnection() {
		this.ws = null;
		this.connected = false;
		this.ready = false;
		console.log('Connection closed');
	}

	command_dispatch(pack) {
		var replyData = pack.length >= 1 ? pack[1] : null;
		var replyDataCmd = replyData.length >= 0 ? replyData[0] : null;
		var replyFinal = replyData.length >= 1 ? replyData[1] : null;
		console.log(pack)
		switch (pack[0]) {
			case 'get_favorite':
				this.get_favorite = Object.keys(pack[1]).length == 0 ?
					{ device: [], group: [], scene: [] } : pack[1];
				break;
			case 'interlock':
			case 'changeover':
				this[pack[0] + '_' + pack[1][0]] = pack[1][1];
				break;
			case 'ppd':
				// this[pack[0]] = pack[1]
				break;
			case 'schedule':
				if (replyDataCmd == 'get_program')
					this[`get_${pack[0]}`] = replyFinal;
				else this[replyDataCmd] = replyFinal;
				break;
			case 'bill':
			case 'data':
			case 'rt_en':
			case 'unit_manage':
				this[replyDataCmd] = replyFinal;
				break;
			case 'read_json':
			case 'save_json':
				var dataProcessed = pack[1].data ? pack[1].data : {};
				var assignVar;
				for (const key in this.json_file) {
					var jsonFileObj = this.json_file[key];
					if (pack[1].name == jsonFileObj.file)
						assignVar = jsonFileObj.var;
				}
				if (pack[0] == 'save_json') assignVar += '_save';
				this[assignVar] = dataProcessed;
				break;
			default:
				this[pack[0]] = pack[1];
				break;
		}
	}

	rebootSystem() {
		this.send(['reboot']);
	}

	getSpList() {
		this.send(['get_splist']);
	}

	loadImage(fileName) {
		this.send(['load_image', { name: fileName }])
	}

	editPoint(data) {
		this.send(['op', data]);
	}

	clearFilter(pointId) {
		var data = { [pointId]: { cl_filter: 'on' } }
		this.send(['op', data]);
	}

	getFavorite() {
		this.send(['get_favorite']);
	}
	saveFavorite(data) {
		this.send(['set_favorite', data]);
	}
	updateOp(data) {
		this.send(['op', data]);
	}
	checkUpdate() {
		this.send(['check_update']);
	}
	systemUpdate() {
		this.send(['update']);
	}
	getControllerList() {
		this.send(['get_controller_list', '']);
	}
	changePwd(data) {
		this.send(['change_passwd', data]);
	}

	getAccounts() {
		this.send(['get_account_list']);
	}
	addAccount(data) {
		this.send(['add_account', data]);
	}
	editAccount(data) {
		this.send(['modify_account', data]);
	}
	delAccount(data) {
		this.send(['delete_account', data]);
	}

	getData(cmd, data) {
		this.send(['data', [cmd, data]]);
	}
	getReportSetting() {
		this.send(['data', ['load_report_setting']]);
	}
	saveReportSetting(data) {
		this.send(['data', ['save_report_setting', data]]);
	}
	getHistory(data) {
		this.send(['data', ['get_history', data]]);
	}
	getMeterPoints() {
		this.send(['data', ['meter_point_list']]);
	}
	getTrendPoints() {
		this.send(['data', ['trend_point_list']]);
	}
	getOpTimePoints() {
		this.send(['data', ['optime_point_list']]);
	}
	getPpdPoints() {
		this.send(['data', ['ppd_point_list']]);
	}
	getPpd(data) {
		this.send(['data', ['get_ppd', data]]);
	}
	getTrend(startDTObj, endDTObj, points) {
		var inputPrep = {
			fy: startDTObj.getFullYear(),
			fm: startDTObj.getMonth() + 1,
			fd: startDTObj.getDate(),
			fh: startDTObj.getHours(),
			fmin: startDTObj.getMinutes(),

			ty: endDTObj.getFullYear(),
			tm: endDTObj.getMonth() + 1,
			td: endDTObj.getDate(),
			th: endDTObj.getHours(),
			tmin: endDTObj.getMinutes(),
			points
		}
		this.send(['data', ['get_trend', inputPrep]]);
	}

	getSchedule() {
		this.send(['schedule', ['get_program']]);
	}
	getRealtimeData() {
		this.send(['rt_en', ['get_data']]);
	}

	addSchedule(data) {
		this.send(['schedule', ['add_program', data]]);
	}
	editSchedule(data) {
		this.send(['schedule', ['modify_program', data]]);
	}
	saveScheduleEnable(data) {
		this.send(['schedule', ['enable', data]]);
	}
	delSchedule(id) {
		this.send(['schedule', ['delete_program', id]]);
	}
	addSchAction(data) {
		this.send(['schedule', ['add_pattern', data]]);
	}
	editSchAction(data) {
		this.send(['schedule', ['modify_pattern', data]]);
	}
	delSchAction(id) {
		this.send(['schedule', ['delete_pattern', id]]);
	}
	addSchCalendar(data) {
		this.send(['schedule', ['add_calendar', data]]);
	}
	editSchCalendar(data) {
		this.send(['schedule', ['modify_calendar', data]]);
	}
	delSchCalendar(id) {
		this.send(['schedule', ['delete_calendar', id]]);
	}

	getInterlock() {
		this.send(['interlock', ['get_program']]);
	}
	addInterlock(data) {
		this.send(['interlock', ['add_program', data]]);
	}
	editInterlock(data) {
		this.send(['interlock', ['modify_program', data]]);
	}
	deleteInterlock(data) {
		this.send(['interlock', ['delete_program', data]]);
	}

	getGroups() {
		this.send(['group', ['get_groups']]);
	}
	addGroup(data) {
		this.send(['group', ['add', data]]);
	}
	editGroup(data) {
		this.send(['group', ['modify', data]]);
	}
	delGroup(id) {
		this.send(['group', ['delete', id]]);
	}
	getGroupsClean() {
		var result = [];
		for (const grpId in this.group_list) {
			var group = this.group_list[grpId];
			var vaildPoint = group.mp.filter(pId => this.point_list[pId]);
			if (vaildPoint.length > 0) result.push(grpId);
		} return result;
	}

	getScenes() {
		this.send(['scene', ['get_scenes']]);
	}
	addScene(data) {
		this.send(['scene', ['add_scene', data]]);
	}
	editScene(data) {
		this.send(['scene', ['modify_scene', data]])
	}
	delScene(id) {
		this.send(['scene', ['delete_scene', id]]);
	}
	execScene(id) {
		this.send(['scene', ['exec', id]]);
	}

	getBill(data) {
		this.send(['bill', ['get_bill_data', data]]);
	}
	getHolidayCal() {
		this.send(['bill', ['load_holiday_calendar']]);
	}
	saveHolidayCal(data) {
		this.send(['bill', ['save_holiday_calendar', data]]);
	}
	getOwnerInfo() {
		this.send(['bill', ['load_owner_info']]);
	}
	saveOwnerInfo(data) {
		this.send(['bill', ['save_owner_info', data]]);
	}
	getPriceInfo() {
		this.send(['bill', ['load_price_info']]);
	}
	savePriceInfo(data) {
		this.send(['bill', ['save_price_info', data]]);
	}
	getTenant() {
		this.send(['bill', ['load_tenant']]);
	}
	saveTenant(data) {
		this.send(['bill', ['save_tenant', data]]);
	}

	getChangeover() {
		this.send(['changeover', ['get_groups']]);
	}
	addChangeover(data) {
		this.send(['changeover', ['add', data]]);
	}
	editChangeover(data) {
		this.send(['changeover', ['modify', data]]);
	}
	delChangeover(id) {
		this.send(['changeover', ['delete', id]]);
	}

	getGuestRooms() {
		this.send(['unit_manage', ['get_unit_stat']]);
	}
	getGuestSettings() {
		this.send(['unit_manage', ['get_control_param']]);
	}
	saveGuestSettings(data) {
		this.send(['unit_manage', ['set_control_param', data]]);
	}
	delGuests(data) {
		this.send(['unit_manage', ['delete_unit', data]]);
	}

	getIaq() {
		this.send(['read_json', { name: this.json_file.iaq.file }]);
	}
	saveIaq(data) {
		this.send(['save_json', { name: this.json_file.iaq.file, data }]);
	}

	getGuests() {
		this.send(['read_json', { name: this.json_file.guest.file }]);
	}
	saveGuests(data) {
		this.send(['save_json', { name: this.json_file.guest.file, data }]);
	}

	getLayout() {
		this.send(['read_json', { name: this.json_file.layout.file }]);
	}
	guestRoomRentOp(data) {
		this.send(['unit_manage', ['rent_op', data]]);
	}
}



// switch (replyDataCmd) {
// 	case 'get_trend':
// 		var data_trend = replyFinal.data;
// 		console.log(data_trend);
// 		for (const dt_point_id in data_trend) {
// 			var trendPointKey = this.trend_point_list.find(p => p[0] == dt_point_id)[1];
// 			console.log(trendPointKey);
// 			console.log(this.point_list[dt_point_id]);
// console.log(data_trend[k][trendPointKey]);
// console.log(`${k}: ${data_trend[k]}`);
// 		}
// 		break;
// }
// console.log(replyFinal);
// console.log(this);




// switch (pack[1].name) {
// 	case this.json_iaq:
// 		this.iaq_monitoring_settings = pack[1].data ? pack[1].data : {};
// 		break;
// 	case this.json_guest:
// 		this.guest_room_settings = pack[1].data ? pack[1].data : {};
// 		break;
// }