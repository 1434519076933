<template>
  <div class="pop-up user device-setting system-setting device-page">
    <div class="content-holder">
      <div class="left-content ">
        <PopUpHead :details="details" :hideBorderBtm="true" :leftArr="true" :hideCross="true" :small="true" />
        <div class="nav-list nav-hori-holder  " ref="navListScroll">
          <div class="frame">
            <div v-for="key in slicedList" :key="key">
              <NavHorizontal v-if="pointObj[key]" :icon="IconsHelper.iconMain(pointObj[key])" :name="pointObj[key].name"
                :isIconActiveColor="false" @click="pointSelected({ key, value: pointObj[key] })" />
            </div>
          </div>
        </div>
        <div class="vertical-center">
          <NavPagination direction="up" :expandHori="true" />
          <NavPagination direction="down" :expandHori="true" />
        </div>
      </div>
      <div v-if="pointDetails" class="right-frame">
        <PopUpToast />
        <PopUpHead :details="{ name: 'edit_device' }" rightBtnTxt="save" :hideBorderBtm="true" />
        <div class="edit-section can-scroll">
          <div class="frame main-icon-holder">
            <img class="icon" :src="IconsHelper.iconMain(pointDetails)" />
            <div class="icon-edit">
              <img :src="ReiriIcons.edit_2" @click="iconSelectionClicked" class="to-white" />
            </div>
          </div>
          <!-- name -->
          <FrameInput v-if="pointDetails.hasOwnProperty('name')" class="name" varStr="pointDetails" varStr2="name"
            placeholder="name" />
          <!-- notification -->
          <div v-if="toShowNotify.includes(pointDetails.type)" class="frame notification vertical-center">
            <div class="upper-content">
              <div class="left-text">
                <div>{{ stringTable.word("notify") }}
                </div>
              </div>
              <div class="right-content">
                <label class="switch">
                  <input type="checkbox" checked v-model="pointDetails.notify">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <!-- {{ pointDetails }}
          {{ limit }} -->
          <!-- unit -->
          <!-- <div v-if="pointDetails.hasOwnProperty('unit')" class="frame unit vertical-center">
            <div class="upper-content">
              <div class="left-text vertical-center">
                <div>{{ stringTable.word("unit") }}</div>
              </div>
              <div class="right-content">
                <input class="input-unit" :type="'text'" v-model="pointDetails.unit" />
              </div>
            </div>
          </div> -->

          <!-- limit_valid, upper and lower limit -->
          <div
            v-if="(pointDetails.hasOwnProperty('limit_valid') || pointDetails.hasOwnProperty('limit')) && !['Level', 'LevelSw'].includes(pointDetails.type)"
            class="frame setting">
            <div class="upper-content" v-if="pointDetails.hasOwnProperty('limit_valid')">
              <div class="left-text">
                <div>{{ stringTable.word("limit_valid") }}
                </div>
              </div>
              <div class="right-content">
                <label class="switch">
                  <input type="checkbox" checked v-model="pointDetails.limit_valid">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div v-if="pointDetails.hasOwnProperty('limit')" class="upper-content">
              <div class="left-text vertical-center">
                <div>{{ stringTable.word("up_lim") }}</div>
              </div>
              <div class="right-content vertical-center">
                <input v-if="pointDetails.unit == 'DEG'" class="input-unit" :type="'text'" v-model="upperLimitValue" />
                <input v-else class="input-unit" :type="'text'" v-model="limit.upper" />
                <div class="unit-display">{{ pointDetails.type == 'Ac' ? stringTable.word('DEG') :
                  stringTable.word(pointDetails.unit) }}
                </div>
              </div>
            </div>
            <div v-if="pointDetails.hasOwnProperty('limit')" class="upper-content">
              <div class="left-text vertical-center">
                <div>{{ stringTable.word("low_lim") }}</div>
              </div>
              <div class="right-content vertical-center">
                <input v-if="pointDetails.unit == 'DEG'" class="input-unit" :type="'text'" v-model="lowerLimitValue" />
                <input v-else class="input-unit" :type="'text'" v-model="limit.lower" />
                <div class="unit-display">{{ pointDetails.type == 'Ac' ? stringTable.word('DEG') :
                  stringTable.word(pointDetails.unit) }}
                </div>
              </div>
            </div>
          </div>

          <!-- temp_limit_valid, temp_limit -->
          <div v-if="pointDetails.hasOwnProperty('temp_limit_valid') || pointDetails.hasOwnProperty('temp_limit')"
            class="frame setting">
            <div class="upper-content" v-if="pointDetails.hasOwnProperty('temp_limit_valid')">
              <div class="left-text">
                <div>{{ stringTable.word("temp_limit") }}
                </div>
              </div>
              <div class="right-content">
                <label class="switch">
                  <input type="checkbox" checked v-model="pointDetails.temp_limit_valid">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div v-if="pointDetails.hasOwnProperty('temp_limit')" class="upper-content">
              <div class="left-text vertical-center">
                <div>{{ stringTable.word("rt_ulim") }}</div>
              </div>
              <div class="right-content vertical-center">
                <input class="input-unit" type="text" v-model="limitTemp.upper" />
                <div class="unit-display">{{ pointDetails.type == 'Ac' || pointDetails.type == 'Refrige' ? stringTable.word('DEG') :
                  stringTable.word(pointDetails.unit) }}
                </div>
              </div>
            </div>
            <div v-if="pointDetails.hasOwnProperty('temp_limit')" class="upper-content">
              <div class="left-text vertical-center">
                <div>{{ stringTable.word("rt_llim") }}</div>
              </div>
              <div class="right-content vertical-center">
                <input class="input-unit" type="text" v-model="limitTemp.lower" />
                <div class="unit-display">{{ pointDetails.type == 'Ac' || pointDetails.type == 'Refrige' ? stringTable.word('DEG') :
                  stringTable.word(pointDetails.unit) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.device-setting .unit-display {
  margin-left: 10px;
}

.head-title {
  font-size: 1.3em;
  text-align: center;
}

.device-setting .nav-list {
  margin-top: 8px;
  margin-bottom: 2px;
}

.device-setting .name-length {
  color: var(--active);
}

.device-setting .setting {
  padding: 3px 0;
}

.device-setting .name .left-text {
  padding-left: 0px;
  width: 100%;
}

.device-setting .input-unit {
  text-align: center;
  background-color: var(--inputArea);
  margin: 2px auto;
  height: 24px !important;
}

.device-setting .right-content {
  /* padding-left: 10px; */
  margin-left: auto;
  margin-right: 10px;
  width: 150px;
}

.device-setting .name .right-content {
  align-items: center;
  justify-content: right;
  margin-left: auto;
  margin-right: 10px;
  width: 150px;
  font-size: 14px;
}

.device-setting .name,
.device-setting .notification,
.device-setting .setting {
  /* height: 40px; */
  border-radius: 8px;
  margin-bottom: 15px;
  margin-left: 4px;
  margin-right: 4px;
}

.device-setting .main-icon-holder {
  position: relative;
  height: 90px;
  width: 90px;
  margin: 10px auto;
  margin-bottom: 30px;
}

.device-setting .edit-section .icon {
  height: 100%;
  width: 100%;
  padding: 10px;
}

.device-setting .edit-section .icon-edit {
  position: absolute;
  bottom: -20px;
  right: -25px;
  height: 45px;
  width: 45px;
  background-color: var(--active);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.device-setting .edit-section .icon-edit img {
  width: 30px;
  height: 30px;
}

.device-setting .edit-section {
  max-height: 500px;
}

.device-setting .head-title {
  margin-bottom: 0px;
}
</style>

<script>
import PopUpHead from './PopUpHead.vue'
import FrameInput from './FrameInput.vue'
import NavHorizontal from './NavHorizontal.vue'
import PopUpToast from './PopUpToast.vue'
import NavPagination from './NavPagination.vue'

export default {
  name: "PopUpDeviceSetting",
  data() {
    return {
      details: { name: "dev_settings" },
      pointDetails: '',
      pointId: '',
      currentPoint: {},
      toast: {},
      isError: false,
      clonedCommModule: {},
      toShowNotify: ["Status", "Switch", "LevelSw", "Ac", "Hrv", "Rgbw", "Shutter", "Lock", "SensorStat", "Occupied", "Dehumi", "Apu", "Refrige"],
      limit: { 'lower': '', 'upper': '' },
      limitTemp: { 'lower': '', 'upper': '' },
      page: 1,
      itemPerPage: 14,
      slicedList: [],
    }
  },
  props: {
    commModule: Object,
    cache: Object,
  },
  created() {
    this.clonedCommModule = Object.assign({}, this.commModule);
  },
  mounted() {
    if (this.cache) {
      this.pointDetails = this.cache.content;
      this.pointId = this.cache.pointId;
      this.page = this.cache.page;
    }
  },
  computed: {
    upperLimitValue: {
      get() {
        return this.pointDetails.limit[1] ? this.tempConvert.tempConvert(this.pointDetails.limit[1]) : this.pointDetails.limit[1]
      },
      set(value) {
        this.pointDetails.limit[1] = value ? this.tempConvert.tempRevConvert(value) : value
      }
    },
    lowerLimitValue: {
      get() {
        return this.pointDetails.limit[0] ? this.tempConvert.tempConvert(this.pointDetails.limit[0]) : this.pointDetails.limit[0]
      },
      set(value) {
        this.pointDetails.limit[0] = value ? this.tempConvert.tempRevConvert(value) : value
      }
    },
    upperTempLimitValue: {
      get() {
        return this.pointDetails.temp_limit[1] ? this.tempConvert.tempConvert(this.pointDetails.temp_limit[1]) : this.pointDetails.temp_limit[1]
      },
      set(value) {
        this.pointDetails.temp_limit[1] = value ? this.tempConvert.tempRevConvert(value) : value
      }
    },
    lowerTempLimitValue: {
      get() {
        return this.pointDetails.temp_limit[0] ? this.tempConvert.tempConvert(this.pointDetails.temp_limit[0]) : this.pointDetails.temp_limit[0]
      },
      set(value) {
        this.pointDetails.temp_limit[0] = value ? this.tempConvert.tempRevConvert(value) : value
      }
    },
    pointObj() {
      return this.clonedCommModule.point_list;
    },
    mainList() {
      return Object.keys(this.pointObj)
    },
  },
  methods: {
    isTemp() {
      if (['Ac', 'Refrige'].includes(this.pointDetails.type))
        return true
      else if (Object.prototype.hasOwnProperty.call(this.pointDetails, 'unit'))
        return this.pointDetails.unit == 'DEG' ? true : false
      else
        return false
    },
    clickRightBtn() {
      if (this.ConvertHelper.isEmptyString(this.pointDetails.name)) {
        this.toast = { text: 'no_name', isError: true, }
        return
      }
      if (this.convertRevTemp() == false) {
        this.toast = { text: 'lower_upper_error', isError: true, }
        return
      }
      if (this.pointDetails.unit != 'DEG' && Object.keys(this.pointDetails).includes('limit')) {
        this.pointDetails.limit = [this.limit.lower != null ? parseFloat(this.limit.lower) : null, 
          this.limit.upper != null ? parseFloat(this.limit.upper) : null];
      }
      this.currentPoint[this.pointId] = this.pointDetails;
      console.log(this.currentPoint[this.pointId]);
      this.commModule.updateOp(this.currentPoint);
      this.isError = false
    },
    iconSelectionClicked() {
      this.NavAssistant.showPopUp({
        name: 'PopUpIcons',
        details: {
          pointId: this.pointId,
          content: this.pointDetails,
          page: this.page,
          // scrollTop: this.$refs.navListScroll.scrollTop,
          iconMap: 'pointIconMap',
        },
        page: this.$parent.page
      }, true);
    },
    pointSelected(item) {
      this.currentPoint = item
      this.pointId = item.key
      this.pointDetails = Object.assign({}, item.value);
      this.convertTemp()
    },
    convertTemp() {
      if (this.isTemp()) {
        if (Object.prototype.hasOwnProperty.call(this.pointDetails, 'temp_limit')) {
          this.limitTemp.lower = this.pointDetails.temp_limit[0] != null ? this.tempConvert.tempConvert(this.pointDetails.temp_limit[0]).toFixed(1) : this.pointDetails.temp_limit[0]
          this.limitTemp.upper = this.pointDetails.temp_limit[1] != null ? this.tempConvert.tempConvert(this.pointDetails.temp_limit[1]).toFixed(1) : this.pointDetails.temp_limit[1]
        }
      } else {
        if (Object.prototype.hasOwnProperty.call(this.pointDetails, 'limit')) {
          this.limit.lower = this.pointDetails.limit[0] != null ? this.pointDetails.limit[0].toFixed(1) : this.pointDetails.limit[0]
          this.limit.upper = this.pointDetails.limit[1] != null ? this.pointDetails.limit[1].toFixed(1) : this.pointDetails.limit[1]
        }
      }
    },
    convertRevTemp() {
      if (!this.isTemp())
        return true
      else {
        if (Object.prototype.hasOwnProperty.call(this.pointDetails, 'temp_limit')) {
          this.pointDetails.temp_limit[0] = ![null, ''].includes(this.limitTemp.lower) ? parseFloat(this.tempConvert.tempRevConvert(this.limitTemp.lower)) : null
          this.pointDetails.temp_limit[1] = ![null, ''].includes(this.limitTemp.upper) ? parseFloat(this.tempConvert.tempRevConvert(this.limitTemp.upper)) : null

          // check for upper value lower than lower
          if (this.pointDetails.temp_limit[0] != null && this.pointDetails.temp_limit[1] != null) {
            if (this.pointDetails.temp_limit[0] >= this.pointDetails.temp_limit[1])
              return false
            else
              return true
          } else
            return true
        } else {
          this.pointDetails.limit[0] = ![null, ''].includes(this.lowerLimitValue) ? parseFloat(this.tempConvert.tempRevConvert(this.lowerLimitValue)) : null
          this.pointDetails.limit[1] = ![null, ''].includes(this.upperLimitValue) ? parseFloat(this.tempConvert.tempRevConvert(this.upperLimitValue)) : null

          // check for upper value lower than lower
          if (this.pointDetails.limit[0] != null && this.pointDetails.limit[1] != null) {
            if (this.pointDetails.limit[0] >= this.pointDetails.limit[1])
              return false
            else
              return true
          } else
            return true
        }
      }
      // else {
      //   // if (this.pointDetails.limit[0] == null && this.pointDetails.limit[1] == null)
      //   //   return true
      //   if (Object.prototype.hasOwnProperty.call(this.pointDetails, 'limit')) {
      //     this.pointDetails.limit[0] = ![null, ''].includes(this.limit.lower) ? parseFloat(this.tempConvert.tempRevConvert(this.limit.lower)) : null
      //     this.pointDetails.limit[1] = ![null, ''].includes(this.limit.upper) ? parseFloat(this.tempConvert.tempRevConvert(this.limit.upper)) : null
      //   }
      //   // check for upper value lower than lower
      //   if (this.pointDetails.limit[0] != null && this.pointDetails.limit[1] != null) {
      //     if (this.pointDetails.limit[0] >= this.pointDetails.limit[1])
      //       return false
      //     else
      //       return true
      //   } else
      //     return true
      // }
    },
    prev() {
      this.pointDetails = '';
    },
  },
  watch: {
    'commModule.op'() {
      console.log(this.commModule.op.result);
      if (this.commModule.op.result == "OK") {
        this.toast = { text: 'succeed' }
      } else { //error
        this.toast = { text: this.commModule.op.result }
      }
      this.clonedCommModule = Object.assign({}, this.commModule);
      this.isError = false
    },
  },
  components: {
    PopUpHead,
    FrameInput,
    NavHorizontal,
    PopUpToast,
    NavPagination
  }
}
</script>
<!-- <div class="pop-up-head">
  <div class="edit-header">
    <div class="edit-title vertical-center">
      <div class="right-title">{{ stringTable.word("edit_device") }}</div>
      <div class="save-btn top-btn frame" @click="saveDeviceSetting">{{ stringTable.word("save") }}</div>
    </div>
  </div>
  <div class="cross-holder frame vertical-center" @click="back">
    <img :src="ReiriIcons.cross" />
  </div>
</div> -->